<template>
  <Error v-if="hasError" />
  <!-- Stop pages from completely refreshing on route changes -->
  <router-view v-else-if="$route.path.indexOf('hierarchy') >= 0" />
  <router-view v-else-if="$route.path.indexOf('menus') >= 0" />
  <router-view v-else-if="$route.path.indexOf('food-item') >= 0" />
  <router-view v-else-if="$route.path.indexOf('installations-management') >= 0" />
  <router-view v-else-if="$route.path.indexOf('systems-management') >= 0" />
  <router-view
    v-else
    :key="$route.path"
  />

  <div id="modal-dropzone" />
</template>

<script setup>
import Error from '@/pages/Error/Error.vue'
import { loadLanguageAsync } from '@/languages/index'
import moment from 'moment'
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

// importing all used locales for moment here because i couldn't find a better way of doing it like we used to in webpack with the contextReplacementPlugin
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/zh-cn'
import 'moment/dist/locale/nl'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/de'
import 'moment/dist/locale/it'
import 'moment/dist/locale/nb'
import 'moment/dist/locale/pl'
import 'moment/dist/locale/pt'
import 'moment/dist/locale/es'
import 'moment/dist/locale/sv'
import 'moment/dist/locale/tr'
import 'moment/dist/locale/vi'
import 'moment/dist/locale/ja'
import 'moment/dist/locale/el'
import 'moment/dist/locale/th'
import 'moment/dist/locale/ar'
import 'moment/dist/locale/ko'
import 'moment/dist/locale/bg'
import 'moment/dist/locale/ru'
import 'moment/dist/locale/et'
import 'moment/dist/locale/is'
import 'moment/dist/locale/lt'
import 'moment/dist/locale/lv'

const { getters } = useStore()
const locale = computed(() => getters['auth/locale'])
const hasError = computed(() => getters['auth/error'] !== false)
const router = useRouter()

watch(locale, (newLocale) => {
  if (import.meta.env.VUE_APP_INTERCOM_INTEGRATION) {
    window.Intercom('update', {
      language_override: newLocale,
    })
  }
})
if (hasError.value) {
  router.push({ name: '404' })
  // generic redirect to 404 page on any app level error
} else {
  loadLanguageAsync(locale.value)

  // speicifc hack to get zh-HANS working
  moment.updateLocale('zh-HANS', {
    parentLocale: 'zh-cn',
  })

  moment.locale(locale.value)
}
</script>

<style lang="scss" src="@/assets/styles/app.scss"></style>
