import axios, { spitURL } from '../api-axios'
import mapInstallation from '../api/mappings/get-installation'
import mapSettingsMetadata from '../api/mappings/get-settings-metadata'
import mapInstallationSettings from '../api/mappings/get-installation-settings'
import { keyBy } from 'lodash-es'
export default {
  postInstallation(body) {
    return axios
      .post('installations-management/installations', body)
      .then((response) => response.data)
      .then((json) => json.data)
      .then(mapInstallation)
  },
  fetchInstallations(query) {
    return axios
      .get(spitURL('installations-management/installations', query))
      .then((response) => response.data)
      .then((json) => {
        const installations = json.data
        return {
          records: keyBy(installations.map(mapInstallation), 'id'),
          sortedIds: installations.map(({ id }) => id),
          total: json.metadata.total,
        }
      })
  },
  fetchInstallation(installationId) {
    return axios
      .get(`installations-management/installations/${installationId}`)
      .then((response) => response.data)
      .then((json) => json.data)
      .then(mapInstallation)
  },
  fetchInstallationSettings(installationId) {
    return axios
      .get(`installations-management/installations/${installationId}/settings`)
      .then((response) => response.data)
      .then((json) => json.data)
      .then(mapInstallationSettings)
      .then((settings) => keyBy(settings, 'key'))
  },
  patchInstallation({ id, ...installation }) {
    return axios
      .patch(`installations-management/installations/${id}`, installation)
      .then((response) => response.data)
      .then((json) => json.data)
      .then(mapInstallation)
  },
  fetchSettingsMetadata() {
    return axios
      .get(
        spitURL('settings-metadata-management/settings-metadata', {
          filter: {
            availableFor: 'INSTALLATION',
          },
        })
      )
      .then((response) => response.data)
      .then((json) => json.data)
      .then(mapSettingsMetadata)
      .then((settings) => keyBy(settings, 'inputKey'))
  },
  patchSettings({ id, data }) {
    return axios
      .patch(`installations-management/installations/${id}/settings`, data)
      .then((response) => response.data)
      .then((json) => json.data)
      .then(mapInstallationSettings)
      .then((settings) => keyBy(settings, 'key'))
  },
  linkSystem({ installationId, systemId }) {
    return axios
      .post(`installations-management/installations/${installationId}/link/${systemId}`)
      .then((response) => response.data)
      .then((json) => json.data)
  },
  unlinkSystem({ installationId, systemId }) {
    return axios
      .delete(`installations-management/installations/${installationId}/link/${systemId}`)
      .then((response) => response.data)
      .then((json) => json.data)
  },
}
