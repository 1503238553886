import Menu from '@/store/models/menu.js'
import { isUndefined, omitBy } from 'lodash-es'
import { parseOrganisationModel } from '@/store/utils/organisations'

export default function mapMenu(menu) {
  return omitBy(
    new Menu({
      archived: menu.archived,
      id: menu.id,
      name: menu.name,
      organisation: menu.organisation ? parseOrganisationModel(menu.organisation) : undefined,
      description: menu.description,
      created: menu.created,
      originalAuthor: menu.originalAuthor,
      modified: menu.lastUpdated,
      lastUpdateAuthor: menu.lastUpdateAuthor,
      deployed: menu.lastDeployed,
      currency: menu.currency,
      vision: menu.vision,
      locale: menu.language,
      foodGroupIds: menu.foodGroups && menu.foodGroups.map((fg) => fg.id),
      stageIds: menu.stages && menu.stages.map((stage) => stage.id),
      foodItemIds: menu.foodItems && menu.foodItems.map((fi) => fi.id),
      manualOrdering: !menu.alphabeticallySortedChildren,
      activeTask: menu.state && menu.state.key,
      translationsRemaining: menu.itemsWithNoEnglishName || 0,
      parentLocationId: menu.parentLocationId,
      parentLocation: menu.parentLocation,
      numberOfActiveTrackers: menu.numberOfActiveTrackers,
      ...(menu.trackers && { trackers: menu.trackers }),
      // this gets populated separately
      // do not uncomment because it will reset the values when not expected
      // statistics: {
      //   foodItems: 0,
      //   foodItemsPending: 0,
      //   foodItemsApproved: 0,
      //   foodItemsFailed: 0,
      //   foodItemsUnmapped: 0,
      //   foodItemsCostZero: 0,
      //   foodItemsCostNull: 0,
      //   foodItemsPortionMissing: 0,
      //   hasDuplicateMappings: false,
      //   hasParentChildConflicts: false,
      //   hasIssues: false,
      // },
    }),
    isUndefined
  )
}
