export default function (installation) {
  return {
    id: installation.id,
    name: installation.name,
    organisation: {
      id: installation.organisation.id,
      name: installation.organisation.name,
      qualifiedName: installation.organisation.qualifiedName,
    },
    site: {
      id: installation.site.id,
      name: installation.site.name,
      qualifiedName: installation.site.qualifiedName,
    },
    createdDate: installation.created,
    updatedDate: installation.updated,
    archived: installation.archived,
    menuId: installation.menuId,
    systemLinks: installation.systemLinks.map((systemLink) => ({
      systemId: systemLink.systemId,
      linkStatus: systemLink.linkStatus,
    })),
  }
}
