import { isPlainObject } from 'lodash-es'
import queryString from 'query-string'

export function spitURL(url, query) {
  const { filter, ...rest } = query

  let params = {}

  let parentId = ''

  if (filter) {
    Object.keys(filter).forEach((k) => {
      if (filter[k] !== undefined && filter[k] !== null) {
        // if one of the filters is an object, we need to transform it to our agreed format:
        // ex: `filter: { configuration: { name: 'test name'}}` needs to become `filter[configuration]=name:test name`
        if (isPlainObject(filter[k])) {
          const queryItems = []
          Object.keys(filter[k]).forEach((k2) => {
            queryItems.push(`${k2}:${filter[k][k2]}`)
          })
          params[`filter[${k}]`] = queryItems
        } else {
          params[`filter[${k}]`] = filter[k]
        }
      }
    })
  }

  Object.keys(rest).forEach((k) => {
    if (rest[k] !== undefined && rest[k] !== null) {
      params[k] = rest[k]
    }
  })

  let queryParams = queryString.stringify(params)

  if (queryParams.length) {
    return `${url}${parentId}?${queryParams}`
  }
  return url
}
