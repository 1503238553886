import axios from 'axios'
import queryString from 'query-string'
import { spitURL as spitURLexternal } from './utils/spitURL'

const instance = axios.create({
  baseURL: `${import.meta.env.VUE_APP_API_HOST}${import.meta.env.VUE_APP_API_NAMESPACE}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

instance.interceptors.response.use(undefined, function (err) {
  if (axios.isCancel(err)) {
    return Promise.reject(err)
  }

  return new Promise(function () {
    // add exception for the sites call: even if it returns 403, the user should still see the homepage dashboard
    const isSitesRequest = err.config.url.indexOf('/sites/allowed') >= 0
    const overwriteAccess = [isSitesRequest].filter((condition) => !!condition)
    if (err.response.status === 401) {
      const redirectUri = import.meta.env.VUE_APP_KEYCLOAK_LOGOUT_REDIRECT_URL
      const opts = redirectUri ? { redirectUri } : {}

      window.keycloak.logout(opts)
    }
    if (err.response.status === 403 && !overwriteAccess.length) {
      window.location.assign(new URL('access-denied', window.location.origin))
      return
    }
    throw err.response
  })
})

export default instance

// moved original function outside because it was easier to write tests with mocked axios instance
// kept original export here so as to not change ALL the files
export function spitURL() {
  return spitURLexternal(...arguments)
}

// Serialize query params and add to url
export function buildURL(url, query) {
  let params = {}
  const { filters, search, sortBy, pageNumber, pageSize, facetGroupCode } = query

  if (filters) {
    params = formatFilters(filters)
  }

  if (sortBy) {
    params.sortBy = sortBy
  }

  if (search) {
    params.search = search
  }

  if (pageNumber) {
    params.page = pageNumber
  }

  if (pageSize) {
    params.size = pageSize
  }

  ;['offset', 'limit'].forEach((key) => {
    if (query[key] !== undefined) {
      params[key] = query[key]
    }
  })

  if (facetGroupCode) {
    params.facetGroupCode = facetGroupCode
  }

  let queryParams = queryString.stringify(params)

  if (queryParams.length) {
    return `${url}?${queryParams}`
  }
  return url
}

export function formatFilters(filters = {}) {
  return Object.keys(filters).reduce((params, k) => {
    params[`filter[${k}]`] = filters[k]
    return params
  }, {})
}
