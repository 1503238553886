import api from '../../api/menus'
import { filter, find, isEmpty, map, uniq, cloneDeep, difference, isEqual } from 'lodash-es'

export default {
  namespaced: true,
  state: {
    /**
     * @typedef changeRecord
     * @prop {string} id - own id used for references
     * @prop {string} itemId - the id of the changed record
     * @prop {string} itemType - STAGE, FOOD_GROUP, FOOD_ITEM
     * @prop {string} key - the property that was changed
     * @prop {string} type - the type of change (REFERENCE, STATIC)
     * @prop {boolean} ignored - if the change should not be saved
     * @prop {string|array} oldValue - static value or list of references
     * @prop {string|array} newValue - static value or list of references
     */
    /** @type {Object.<string, changeRecord>} */
    changeRecords: {},

    /**
     * @typedef foodItemRecord
     * @prop {string} id - internal id
     * @prop {string} itemId - the actual id of the food item
     * @prop {string} name
     * @prop {string} action - the type of change (NEW, MODIFIED, DELETE)
     * @prop {string[]} changeIds
     */
    /** @type {Object.<string, foodItemRecord>} */
    foodItemRecords: {},

    /**
     * @typedef foodGroupRecord
     * @prop {string} id - internal id
     * @prop {string} foodGroupId - the actual id of the food group
     * @prop {string} name
     * @prop {string} action, - the type of change (NEW, MODIFIED, DELETE)
     * @prop {string[]} changeIds references to the change records this is apart of
     */
    /** @type {Object.<string, foodGroupRecord>} */
    foodGroupRecords: {},
    foodGroupRecordsBackup: {},
    /**
     * @typedef stageRecord
     * @prop {string} id - internal id
     * @prop {string} stageId - the actual id of the stage
     * @prop {string} name
     * @prop {string} action - the type of change (NEW, MODIFIED, DELETE)
     * @prop {string[]} changeIds references to the change records this is apart of
     */
    /** @type {Object.<string, stageRecord>} */
    stageRecords: {},
  },
  getters: {
    foodItemChanges: (state) => filter(state.changeRecords, { itemType: 'FOOD_ITEM' }),
    /** @returns {boolean} */
    hasChanges: (state, getters) => !isEmpty(getters.foodItemChanges),
    hasValidChanges: (state, getters) =>
      getters.ignoredChanges.length !== getters.changeRecordsList.length,

    // SINGLE ITEM GETTERS

    /**
     * @typedef {Object} foodItem
     * @prop {string} id
     * @prop {string} name
     * @prop {string} type
     * @prop {string} action
     * @prop {changeRecord[]} changes
     */

    /**
     * @param {string} id - a foodItemRecord id
     * @return {foodItem}
     */
    foodItemById: (state, getters) => (id) => {
      let foodItem = state.foodItemRecords[id]
      let changes = getters.changesById(foodItem.changeIds)
      return {
        id: foodItem.id,
        name: foodItem.name,
        type: foodItem.type,
        action: foodItem.action,
        changes,
        ignored: changes.length === changes.filter(({ ignored }) => ignored).length,
      }
    },

    /**
     * @typedef {Object} foodChange
     * @prop {string} id
     * @prop {string} key
     * @prop {string} type
     * @prop {(string|foodGroupRecord|stageRecord)} oldValue
     * @prop {(string|foodGroupRecord|stageRecord)} newValue
     */

    /**
     * Single food item change with values embeded
     * @param {string} id - a changeRecord id
     * @returns {foodChange}
     */
    changeById: (state, getters) => (id) => {
      let change = cloneDeep(state.changeRecords[id])
      if (change.key === 'foodGroups') {
        let oldValue = (getters.foodGroupRecordsById(change.oldValue) || []).map((value) => {
          let changes = value.changeIds.map((id) => cloneDeep(state.changeRecords[id]))
          let stageChange = changes.find((change) => change.key === 'stages')
          let stage =
            stageChange && stageChange.oldValue && state.stageRecords[stageChange.oldValue[0]]
          return { ...value, stage }
        })
        let newValue = (getters.foodGroupRecordsById(change.newValue) || []).map((value) => {
          let changes = value.changeIds.map((id) => cloneDeep(state.changeRecords[id]))
          let stageChange = changes.find((change) => change.key === 'stages')
          let stage =
            stageChange && stageChange.newValue && state.stageRecords[stageChange.newValue[0]]
          return { ...value, stage }
        })
        change.oldValue = oldValue
        change.newValue = newValue
      }
      if (change.key === 'stages') {
        change.oldValue = getters.stageRecordsById(change.oldValue)
        change.newValue = getters.stageRecordsById(change.newValue)
      }
      return change
    },
    /**
     * get only the name change record from a food item
     * @returns {changeRecord}
     */
    nameChangeForFoodItem: (state, getters) => (foodItemId) => {
      return getters.changeRecordsForFoodItem(foodItemId).find((change) => change.key === 'name')
    },
    nameEnglishChangeForFoodItem: (state, getters) => (foodItemId) => {
      return getters
        .changeRecordsForFoodItem(foodItemId)
        .find((change) => change.key === 'nameEnglish')
    },

    // single field change
    fieldChangeForFoodItem:
      (state, getters) =>
      ({ foodItemId, key }) => {
        return getters.changeRecordsForFoodItem(foodItemId).find((change) => change.key === key)
      },

    // LIST GETTERS
    // lists of food items

    /** @returns {string[]} all food item record ids */
    foodItemIds: (state) => Object.keys(state.foodItemRecords),
    /** @returns {foodItemRecord[]} all food item records */
    foodItemRecordsList: (state) => Object.values(state.foodItemRecords),
    /**
     * @param {string[]} ids - foodItemRecord ids
     * @returns {foodItem[]}
     */
    foodItemsById: (state, getters) => (ids) => ids.map(getters.foodItemById),
    /** @returns {foodItem[]} */
    foodItemsGroupedByName: (state, getters) => {
      return getters.foodItemsById(getters.foodItemIds)
    },
    /**
     * List of food items that have a specific field changed
     * @param {string} key - field name
     * @returns {foodItem[]} - changes will be also filtered to only have the one field change
     */
    foodItemsFilteredByKey: (state, getters) => (key) => {
      let foodChangesContainingTheKey = getters.changeRecordsList
        .filter((change) => change.key === key && change.action !== 'DELETE')
        .map((change) => change.itemId)
      return getters.foodItemsById(foodChangesContainingTheKey).map((item) => {
        return {
          ...item,
          changes: item.changes.filter((change) => change.key === key),
        }
      })
    },

    // food item changes
    /** @returns {changeRecord[]} */
    changeRecordsList: (state) => Object.values(state.changeRecords),
    /** @returns {foodChange[]} */
    changesById: (state, getters) => (foodChangeIds) => foodChangeIds.map(getters.changeById),
    changeRecordsById: (state) => (changeIds) => changeIds.map((id) => state.changeRecords[id]),
    /** @returns {foodChange[]} */
    changeRecordsForFoodItem: (state, getters) => (foodItemId) => {
      let foodItem = state.foodItemRecords[foodItemId]
      return getters.changesById(foodItem.changeIds)
    },
    /** @returns {changeRecord[]} */
    ignoredChanges: (state) => {
      return Object.values(state.changeRecords).filter((change) => change.ignored)
    },

    // lists of food groups
    /** @returns {string[]} list of all food group ids */
    foodGroupIds: (state) => Object.keys(state.foodGroupRecords),
    /** @returns {foodGroupRecord[]} */
    foodGroupRecordsList: (state) => Object.values(state.foodGroupRecords),
    /** @returns {foodGroupRecord} */
    foodGroupRecordsById: (state) => (ids) => ids && ids.map((id) => state.foodGroupRecords[id]),
    extractFoodGroupChanges: (state, getters) => (changeIds) => {
      return getters.changeRecordsById(changeIds).filter(({ key }) => key === 'foodGroups')
    },
    /**
     * A list of food groups with all their food item changes
     * @returns {{
     *  id: String,
     *  name: String,
     *  oldFoodItems: foodItem[],
     *  newFoodItems: foodItem[]
     * }}
     */
    foodGroupsWithFoodItems: (state, getters) => {
      return map(state.foodGroupRecords, (foodGroup) => {
        let changes = getters.changesById(foodGroup.changeIds)
        let foodItems = changes.reduce(
          ({ oldFoodItems, newFoodItems }, change) => {
            let oldFoodItem = find(change.oldValue, { id: foodGroup.id }) && change.itemId
            let newFoodItem = find(change.newValue, { id: foodGroup.id }) && change.itemId
            if (oldFoodItem) oldFoodItems.push(oldFoodItem)
            if (newFoodItem) newFoodItems.push(newFoodItem)
            return { oldFoodItems, newFoodItems }
          },
          { oldFoodItems: [], newFoodItems: [] }
        )
        return {
          id: foodGroup.id,
          name: foodGroup.name,
          oldFoodItems: getters.foodItemsById(uniq(foodItems.oldFoodItems)),
          newFoodItems: getters.foodItemsById(uniq(foodItems.newFoodItems)),
        }
      })
    },

    // lists of stages
    /** @returns {string[]} list of all stage ids*/
    stageIds: (state) => Object.keys(state.stageRecords),
    /** @returns {stageRecord[]} */
    stagesRecordsList: (state) => Object.values(state.stageRecords),
    /** @returns {stageRecord} */
    stageRecordsById: (state) => (ids) => ids && ids.map((id) => state.stageRecords[id]),
    extractStageChanges: (state, getters) => (changeIds) => {
      let stageChanges = getters.changeRecordsById(changeIds).filter(({ key }) => key === 'stages')
      let foodGroupChanges = getters.extractFoodGroupChanges(changeIds)
      let foodGroupIds = foodGroupChanges
        .flatMap((change) => [].concat(change.newValue, change.oldValue))
        .filter((id) => !!id)
      let foodGroups = getters.foodGroupRecordsById(foodGroupIds)
      let changedFoodGroupsChanges = foodGroups.flatMap((fg) => {
        return getters.changeRecordsById(fg.changeIds)
      })
      let changedFoodGroupStageChanges = changedFoodGroupsChanges.filter(
        ({ key }) => key === 'stages'
      )
      return [].concat(stageChanges, changedFoodGroupStageChanges)
    },
    /**
     * A list of stages with all their food item changes
     * @returns {{
     *  id: String,
     *  name: String,
     *  oldFoodItems: foodItem[],
     *  newFoodItems: foodItem[]
     * }}
     */
    stagesWithFoodItems: (state, getters) => {
      return Object.values(state.stageRecords).map((stage) => {
        let changes = getters.changesById(stage.changeIds)
        let foodItems = changes.reduce(
          ({ oldFoodItems, newFoodItems }, change) => {
            let oldFoodItem = find(change.oldValue, { id: stage.id }) && change.itemId
            let newFoodItem = find(change.newValue, { id: stage.id }) && change.itemId
            if (oldFoodItem) oldFoodItems.push(oldFoodItem)
            if (newFoodItem) newFoodItems.push(newFoodItem)
            return { oldFoodItems, newFoodItems }
          },
          { oldFoodItems: [], newFoodItems: [] }
        )
        return {
          id: stage.id,
          name: stage.name,
          oldFoodItems: getters.foodItemsById(uniq(foodItems.oldFoodItems)),
          newFoodItems: getters.foodItemsById(uniq(foodItems.newFoodItems)),
        }
      })
    },
    /**
     * A list of stages with all their food group changes
     * @returns {{
     *  id: String,
     *  name: String,
     *  oldFoodGroups: String[] - list of ids,
     *  newFoodGroups: String[] - list of ids
     * }}
     */
    stagesWithFoodGroupChanges: (state) => {
      return Object.values(state.stageRecords)
        .map((stage) => {
          let changes = stage.changeIds
            .map((id) => state.changeRecords[id])
            .find(
              (change) => change.key === 'foodGroups' && ['MODIFIED', 'NEW'].includes(change.action)
            )

          return changes
        })
        .filter((f) => !!f)
      // return Object.values(state.stageRecords).map(stage => {
      //   let changes = getters.changesById(stage.changeIds).filter(change => change.type === 'foodGroups')
      //   let foodGroups = changes.reduce(
      //     ({ oldFoodGroups, newFoodGroups }, change) => {
      //       let oldFoodGroup = find(change.oldValue, { id: stage.id }) && change.itemId
      //       let newFoodGroup = find(change.newValue, { id: stage.id }) && change.itemId
      //       if (oldFoodGroup) oldFoodGroups.push(oldFoodGroup)
      //       if (newFoodGroup) newFoodGroups.push(newFoodGroup)
      //       return { oldFoodGroups, newFoodGroups }
      //     },
      //     { oldFoodGroups: [], newFoodGroups: [] }
      //   )
      //   return {
      //     id: stage.id,
      //     name: stage.name,
      //     oldFoodGroups: getters.foodGroupsById(uniq(foodGroups.oldFoodGroups)),
      //     newFoodGroups: getters.foodGroupsById(uniq(foodGroups.newFoodGroups))
      //   }
      // })
    },

    /**
     * check if stage or food group already exist in menu
     * @returns {Boolean}
     */
    recordIdExistsInMenu:
      (state, geters, rootState, rootGetters) =>
      ({ id, type }) => {
        switch (type) {
          case 'stage':
            return !!rootGetters['menu-management/stages/stageById'](id).id
          case 'foodGroup':
            return !!rootGetters['menu-management/food-groups/foodGroupById'](id).id
        }
      },
  },
  actions: {
    // Get the data
    getChanges({ commit, dispatch }, { menuId, options }) {
      return dispatch('menu-management/stages/getStages', { menuId }, { root: true })
        .then(() => api.getBulkChanges({ menuId, options }))
        .then((data) => {
          commit('STORE_FOOD_ITEM_RECORDS', data.foodItemRecords)
          commit('STORE_CHANGE_RECORDS', data.changeRecords)
          commit('STORE_STAGE_RECORDS', data.stageRecords)
          commit('STORE_FOOD_GROUP_RECORDS', data.foodGroupRecords)
        })
    },
    uploadChanges({ dispatch }, { menuId, csvFile }) {
      return api.postBulkChanges({ menuId, csvFile }).then(() => dispatch('getChanges', { menuId }))
    },

    // SAVE ACTIONS

    findAndCreateNewStages({ state, getters, dispatch }, { menuId, foodItemId, changeIds }) {
      changeIds = changeIds || state.foodItemRecords[foodItemId].changeIds
      let stageChanges = getters.extractStageChanges(changeIds)
      let stageIds = stageChanges.flatMap((change) => change.newValue).filter((id) => !!id)
      let stages = getters.stageRecordsById(stageIds)
      let newStages = stages.filter(({ action, stageId }) => action === 'NEW' && !stageId)
      if (!newStages.length) {
        return Promise.resolve()
      }
      let newStageIds = newStages.map((stage) => stage.id)
      return Promise.all(newStageIds.map((id) => dispatch('saveStage', { menuId, stageId: id })))
    },

    findAndCreateNewFoodGroups({ state, getters, dispatch }, { menuId, foodItemId, changeIds }) {
      changeIds = changeIds || state.foodItemRecords[foodItemId]
      let foodGroupChanges = getters.extractFoodGroupChanges(changeIds)
      let foodGroupIds = foodGroupChanges.flatMap((change) => change.newValue).filter((id) => !!id)
      let foodGroups = getters.foodGroupRecordsById(foodGroupIds)
      let newFoodGroups = foodGroups.filter(
        ({ action, foodGroupId }) => action === 'NEW' && !foodGroupId
      )
      if (!newFoodGroups.length) {
        return Promise.resolve()
      }
      return Promise.all(
        newFoodGroups.map((foodGroup) => {
          let foodGroupChanges = getters.extractStageChanges(foodGroup.changeIds)
          let stageId = find(foodGroupChanges, { key: 'stages' }).newValue[0]
          return dispatch('saveFoodGroup', {
            menuId,
            foodGroupId: foodGroup.id,
            stageId,
          })
        })
      )
    },

    saveFoodGroupsDependencies(store, refs) {
      let { state, getters, dispatch } = store
      let { menuId, foodItemId, changeIds } = refs
      changeIds = changeIds || state.foodItemRecords[foodItemId]
      let foodGroupChanges = getters.extractFoodGroupChanges(changeIds)
      let foodGroupIds = foodGroupChanges.flatMap((change) => change.newValue)
      let foodGroups = getters.foodGroupRecordsById(foodGroupIds)
      return Promise.all(
        foodGroups.map((foodGroup) => {
          let stageChanges = getters.extractStageChanges(foodGroup.changeIds)
          if (!stageChanges || !stageChanges.length) {
            return Promise.resolve()
          }
          let newStageIds = stageChanges.flatMap((change) => change.newValue).filter((id) => !!id)
          let oldStageIds = stageChanges.flatMap((change) => change.oldValue).filter((id) => !!id)
          if (isEqual(newStageIds, oldStageIds)) {
            return Promise.resolve()
          }
          let stageIds = getters.stageRecordsById(newStageIds).map(({ stageId }) => stageId)
          return dispatch('saveFoodGroup', {
            menuId,
            foodGroupId: foodGroup.id,
            data: {
              stageIds,
            },
          })
        })
      )
    },

    saveFoodItemDependencies({ dispatch }, { menuId, foodItemId, changeIds }) {
      return (
        Promise.resolve()
          // start by saving all new stages
          .then(() => dispatch('findAndCreateNewStages', { menuId, foodItemId, changeIds }))
          // continue with saving all new food groups
          .then(() => dispatch('findAndCreateNewFoodGroups', { menuId, foodItemId, changeIds }))
          // save the correct stage/food group relationships
          .then(() => dispatch('saveFoodGroupsDependencies', { menuId, foodItemId, changeIds }))
      )
    },

    // generate output structure for saving food items
    remapFoodItemForSave({ getters, rootGetters }, { changeIds, action }) {
      let data = getters.changesById(changeIds).reduce(
        (data, change) => {
          let key = change.key
          let oldValue = change.oldValue
          let newValue = change.newValue
          if (change.key === 'externalSystem.name') {
            key = 'externalSystem'
            oldValue = oldValue
              ? rootGetters['menu-management/menus/externalSystemByName'](oldValue)
              : null
            newValue = newValue
              ? rootGetters['menu-management/menus/externalSystemByName'](newValue)
              : null
          }
          if (change.key === 'foodGroups') {
            key = 'foodGroupIds'
            oldValue = uniq(oldValue.map((fg) => fg.foodGroupId))
            newValue = uniq(newValue.map((fg) => fg.foodGroupId))
          }
          if (change.key === 'stages') {
            delete data.old.stages
            delete data.new.stages
          }
          if (change.key === 'portion') {
            oldValue = { value: oldValue }
            newValue = { value: newValue }
          }
          if (change.key === 'taxonomyCode') {
            key = 'taxonomy'
            oldValue = { code: oldValue }
            newValue = { code: newValue }
          }
          if (change.key === 'state') {
            key = 'status'
          }
          data['old'][key] = oldValue
          data['new'][key] = newValue
          return data
        },
        { old: {}, new: {} }
      )
      if (action === 'NEW') {
        return data.new
      } else {
        return data
      }
    },

    saveFoodItem({ state, getters, dispatch }, { menuId, foodItemId, changeIds, saveIgnored }) {
      let foodItem = state.foodItemRecords[foodItemId]
      changeIds = changeIds || foodItem.changeIds
      let changes = getters.changesById(changeIds)
      let hasName = changes.find((change) => change.key === 'name')
      let hasEnglishName = changes.find((change) => change.key === 'nameEnglish')
      // new food items have to be created before saving individual fields
      // this means that we also need to save the name field
      if (foodItem.action === 'NEW' && !foodItem.itemId && !hasName) {
        changeIds.push(getters.nameChangeForFoodItem(foodItemId).id)
      }
      if (foodItem.action === 'NEW' && !foodItem.itemId && !hasEnglishName) {
        changeIds.push(getters.nameEnglishChangeForFoodItem(foodItemId).id)
      }

      // if there are external id and system changes they need to be saved together
      let externalSystemChange = find(changes, { key: 'externalSystem.name' })
      let externalIdChange = find(changes, { key: 'externalId' })
      if (externalSystemChange && !externalIdChange) {
        externalIdChange = getters.fieldChangeForFoodItem({
          foodItemId,
          key: 'externalId',
        })
        if (externalIdChange) {
          changeIds.push(externalIdChange.id)
        }
      }
      if (!externalSystemChange && externalIdChange) {
        externalSystemChange = getters.fieldChangeForFoodItem({
          foodItemId,
          key: 'externalSystem.name',
        })
        if (externalSystemChange) {
          changeIds.push(externalSystemChange.id)
        }
      }
      let savePromise = new Promise((resolve) => {
        if (foodItem.action === 'DELETE') {
          resolve(dispatch('deleteFoodItem', { menuId, foodItemId, changeIds }))
        } else if (foodItem.action === 'NEW' && !foodItem.itemId) {
          resolve(dispatch('createFoodItem', { menuId, foodItemId, changeIds }))
        } else {
          resolve(
            dispatch('saveChanges', {
              menuId,
              foodItemId,
              changeIds,
              saveIgnored,
            })
          )
        }
      })
      return savePromise
        .then(() => dispatch('deleteRemovableDependencies'))
        .then(() => {
          if (!getters.hasChanges) {
            return dispatch('saveStagesWithOrderedFoodGroups', { menuId }).then(() => {
              return dispatch('clearBulkChanges', { menuId })
            })
          }
        })
    },
    // save multiple fields for a food item (or all of them)
    saveChanges(
      { state, dispatch, getters, commit },
      { menuId, foodItemId, changeIds, saveIgnored }
    ) {
      let foodItem = state.foodItemRecords[foodItemId]
      changeIds = changeIds || foodItem.changeIds
      let changes = getters.changesById(changeIds)
      if (!saveIgnored) {
        changes = changes.filter((change) => !change.ignored)
      }
      changeIds = changes.map((change) => change.id)
      // @TODO: filter out ignored changes
      if (!changeIds.length) {
        return Promise.resolve()
      }
      return dispatch('saveFoodItemDependencies', {
        menuId,
        foodItemId,
        changeIds,
      })
        .then(() =>
          dispatch('remapFoodItemForSave', {
            changeIds,
            action: !foodItem.itemId && foodItem.action,
          })
        )
        .then((data) => {
          return api
            .patchFoodItem({
              menuId,
              itemId: foodItem.itemId,
              data,
            })
            .then(() => {
              commit('REMOVE_CHANGE_RECORDS', changeIds)
            })
            .then(() => {
              let foodItem = state.foodItemRecords[foodItemId]
              if (!foodItem.changeIds.length) {
                commit('REMOVE_FOOD_ITEM_RECORDS', [foodItem.id])
              }
            })
        })
    },
    createFoodItem({ state, commit, dispatch }, { menuId, foodItemId, changeIds }) {
      changeIds = changeIds || state.foodItemRecords[foodItemId].changeIds
      let foodItem = state.foodItemRecords[foodItemId]
      return dispatch('saveFoodItemDependencies', {
        menuId,
        foodItemId,
        changeIds,
      })
        .then(() => dispatch('remapFoodItemForSave', { changeIds, action: foodItem.action }))
        .then((data) => api.postFoodItem({ menuId, data }))
        .then((foodItem) => {
          commit('UPDATE_FOOD_ITEM', { id: foodItemId, itemId: foodItem.id })
        })
        .then(() => {
          commit('REMOVE_CHANGE_RECORDS', changeIds)
        })
        .then(() => {
          let foodItem = state.foodItemRecords[foodItemId]
          if (!foodItem.changeIds.length) {
            commit('REMOVE_FOOD_ITEM_RECORDS', [foodItem.id])
          }
        })
    },
    deleteFoodItem({ state, commit }, { menuId, foodItemId, changeIds }) {
      /** @type {foodItemRecord} */
      let foodItem = state.foodItemRecords[foodItemId]
      changeIds = changeIds || foodItem.changeIds
      // @TODO: save dependencies
      return api
        .deleteFoodItem({ menuId, foodItemId: foodItem.itemId })
        .then(() => {
          commit('REMOVE_CHANGE_RECORDS', changeIds)
        })
        .then(() => {
          let foodItem = state.foodItemRecords[foodItemId]
          if (!foodItem.changeIds.length) {
            commit('REMOVE_FOOD_ITEM_RECORDS', [foodItem.id])
          }
        })
    },
    saveAllFoodItems({ getters, dispatch }, { menuId, action, key, saveIgnored = false }) {
      function runSave() {
        let foodItemsToBeSaved = getters.foodItemRecordsList

        // only save a specific subset of actions
        if (action && ['NEW', 'MODIFIED', 'DELETE'].includes(action)) {
          foodItemsToBeSaved = foodItemsToBeSaved.filter((foodItem) => foodItem.action === action)
        }
        // only save a specific field for each food item
        if (key && key !== 'all') {
          foodItemsToBeSaved = foodItemsToBeSaved
            .filter((foodItem) => {
              let changes = getters.changesById(foodItem.changeIds)
              let keyChange = changes.find((change) => change.key === key)
              return ['MODIFIED', 'NEW'].includes(foodItem.action) && keyChange
            })
            .map((foodItem) => {
              let changes = getters.changesById(foodItem.changeIds)
              return {
                ...foodItem,
                changeIds: changes
                  .filter((change) => change.key === key)
                  .map((change) => change.id),
              }
            })
        }

        if (saveIgnored) {
          // identify food items with ignored fields so that we only save those
          foodItemsToBeSaved = foodItemsToBeSaved.filter((foodItem) => {
            let changes = getters.changesById(foodItem.changeIds)
            let ignored = changes.filter((change) => change.ignored)
            return ignored.length
          })
        } else {
          // filter out ignored changes
          foodItemsToBeSaved = foodItemsToBeSaved.filter((foodItem) => {
            let changes = getters.changesById(foodItem.changeIds)
            let ignored = changes.filter((change) => change.ignored)
            return changes.length !== ignored.length
          })
        }

        // save the first item in the resulting list
        // recursively call each item until foodItemsToBeSaved is empty
        let foodItem = foodItemsToBeSaved[0]
        if (foodItem) {
          return dispatch('saveFoodItem', {
            menuId,
            foodItemId: foodItem.id,
            changeIds: saveIgnored
              ? foodItem.changeIds
              : foodItem.changeIds.filter((id) => !getters.changeById(id).ignored),
            saveIgnored: saveIgnored,
          }).then(runSave)
        } else {
          return Promise.resolve()
        }
      }

      return runSave()
    },
    saveFoodGroup({ state, dispatch, commit }, { menuId, foodGroupId, stageId, data }) {
      let foodGroup = state.foodGroupRecords[foodGroupId]
      if (foodGroup.action === 'NEW' && !foodGroup.foodGroupId) {
        let stage = state.stageRecords[stageId]
        return dispatch(
          'menu-management/food-groups/createFoodGroup',
          {
            menuId,
            stageId: stage.stageId,
            data: data || {
              name: foodGroup.name,
              nameEnglish: foodGroup.nameEnglish || foodGroup.name,
            },
          },
          { root: true }
        ).then((foodGroup) => {
          return commit('UPDATE_FOOD_GROUP', { id: foodGroupId, foodGroupId: foodGroup.id })
        })
      } else if (foodGroup.action === 'DELETE') {
        return dispatch(
          'menu-management/food-groups/deleteFoodGroup',
          { menuId, foodGroupId: foodGroup.foodGroupId },
          { root: true }
        ).then(() => {
          return commit('DELETE_FOOD_GROUP_REFERENCES', foodGroupId)
        })
      } else if (data) {
        return dispatch(
          'menu-management/food-groups/saveChanges',
          { menuId, foodGroupId: foodGroup.foodGroupId, data },
          { root: true }
        )
      } else {
        return Promise.resolve()
      }
    },
    saveStage({ state, dispatch, commit }, { menuId, stageId, data }) {
      let stage = state.stageRecords[stageId]
      if (stage.action === 'NEW' && !stage.stageId) {
        return dispatch(
          'menu-management/stages/createNew',
          {
            menuId,
            data: data || {
              name: stage.name,
              nameEnglish: stage.nameEnglish || stage.name,
              foodGroupIds: null,
            },
          },
          { root: true }
        ).then((stage) => {
          commit('UPDATE_STAGE', { id: stageId, stageId: stage.id })
        })
      } else if (stage.action === 'DELETE') {
        return dispatch(
          'menu-management/stages/deleteStage',
          { stageId: stage.stageId },
          { root: true }
        )
      } else if (data) {
        return dispatch(
          'menu-management/stages/saveChanges',
          { menuId, stageId: stage.stageId, data },
          { root: true }
        )
      } else {
        return Promise.resolve()
      }
    },

    saveStagesWithOrderedFoodGroups({ state, getters, dispatch }, { menuId }) {
      let stagesToSave = [...getters.stagesWithFoodGroupChanges]
      function runSave() {
        if (stagesToSave.length) {
          let savingStage = stagesToSave[0]
          return dispatch('saveStage', {
            menuId,
            stageId: savingStage.itemId,
            data: {
              foodGroupIds: savingStage.newValue.map(
                (id) => state.foodGroupRecordsBackup[id].foodGroupId
              ),
            },
          }).then(() => {
            stagesToSave.shift()
            return runSave()
          })
        } else {
          return Promise.resolve()
        }
      }
      return runSave()
    },

    // this deletes stages and food groups that are no longer referenced in changes
    // and have a 'DELETE' action
    deleteRemovableDependencies({ getters, commit }) {
      let remainingFoodGroupsInChanges = getters.foodItemRecordsList
        .flatMap(({ changeIds }) => getters.extractFoodGroupChanges(changeIds))
        .flatMap((change) => [].concat(change.newValue, change.oldValue))
      return Promise.all(
        getters.foodGroupRecordsList.map((foodGroup) => {
          if (!remainingFoodGroupsInChanges.includes(foodGroup.id)) {
            return new Promise((resolve) => {
              if (foodGroup.action === 'DELETE') {
                api.deleteFoodGroup({ foodGroupId: foodGroup.foodGroupId }).then(resolve)
              } else {
                resolve()
              }
            })
              .then(() => {
                commit('REMOVE_FOOD_GROUP', foodGroup.id)
              })
              .then(() => {
                commit('REMOVE_CHANGE_RECORDS', foodGroup.changeIds)
              })
          } else {
            return Promise.resolve()
          }
        })
      ).then(() => {
        let remainingStagesInChanges = getters.foodGroupRecordsList
          .flatMap(({ changeIds }) => getters.extractStageChanges(changeIds))
          .flatMap((change) => [].concat(change.newValue, change.oldValue))
        return Promise.all(
          getters.stagesRecordsList.map((stage) => {
            if (!remainingStagesInChanges.includes(stage.id) && stage.action === 'DELETE') {
              return new Promise((resolve) => {
                if (stage.action === 'DELETE') {
                  api.deleteStage({ stageId: stage.stageId }).then(resolve)
                } else {
                  resolve()
                }
              })

                .then(() => {
                  commit('REMOVE_STAGE', stage.id)
                })
                .then(() => {
                  commit('REMOVE_CHANGE_RECORDS', stage.changeIds)
                })
            } else {
              return Promise.resolve()
            }
          })
        )
      })
    },

    clearBulkChanges({ commit }, { menuId }) {
      return api.clearBulkUpdates({ menuId }).then(() => {
        commit('RESET_STATE')
      })
    },
  },
  mutations: {
    STORE_FOOD_ITEM_RECORDS(state, records) {
      state.foodItemRecords = records
    },
    STORE_FOOD_GROUP_RECORDS(state, records) {
      state.foodGroupRecords = records
      state.foodGroupRecordsBackup = { ...records }
    },
    STORE_STAGE_RECORDS(state, records) {
      state.stageRecords = records
    },
    STORE_CHANGE_RECORDS(state, records) {
      state.changeRecords = records
    },
    REMOVE_FOOD_ITEM_RECORDS(state, ids) {
      ids.forEach((id) => {
        delete state.foodItemRecords[id]
      })
    },
    REMOVE_CHANGE_RECORDS(state, ids) {
      ids.forEach((id) => {
        delete state.changeRecords[id]
      })
      Object.values(state.stageRecords).forEach((stage) => {
        stage.changeIds = difference(stage.changeIds, ids)
        state.stageRecords[stage.id] = stage
      })
      Object.values(state.foodGroupRecords).forEach((foodGroup) => {
        foodGroup.changeIds = difference(foodGroup.changeIds, ids)
        state.foodGroupRecords[foodGroup.id] = foodGroup
      })
      Object.values(state.foodItemRecords).forEach((foodItem) => {
        foodItem.changeIds = difference(foodItem.changeIds, ids)
        state.foodItemRecords[foodItem.id] = foodItem
      })
    },
    RESET_STATE(state) {
      state.foodItemRecords = {}
      state.changeRecords = {}
      state.foodGroupRecords = {}
      state.stageRecords = {}
    },
    UPDATE_FOOD_ITEM(state, foodItem) {
      state.foodItemRecords[foodItem.id] = {
        ...state.foodItemRecords[foodItem.id],
        ...foodItem,
      }
    },
    UPDATE_CHANGE(state, change) {
      state.changeRecords[change.id] = {
        ...state.changeRecords[change.id],
        ...change,
      }
    },
    UPDATE_STAGE(state, stage) {
      state.stageRecords[stage.id] = {
        ...state.stageRecords[stage.id],
        ...stage,
      }
    },
    UPDATE_FOOD_GROUP(state, foodGroup) {
      state.foodGroupRecords[foodGroup.id] = {
        ...state.foodGroupRecords[foodGroup.id],
        ...foodGroup,
      }
      state.foodGroupRecordsBackup[foodGroup.id] = {
        ...state.foodGroupRecordsBackup[foodGroup.id],
        ...foodGroup,
      }
    },
    DELETE_FOOD_GROUP_REFERENCES(state, foodGroupId) {
      delete state.foodGroupRecords[foodGroupId]
      let allChanges = state.changeRecords
      let allChangeKeys = Object.keys(state.changeRecords)
      let foodGroupChangeIds = allChangeKeys.filter((id) => allChanges[id].key === 'foodGroups')

      foodGroupChangeIds.forEach((foodGroupChangeId) => {
        /** @type {changeRecord} */
        let record = state.changeRecords[foodGroupChangeId]
        record.oldValue =
          record.oldValue && record.oldValue.filter((foodGroupId) => foodGroupId !== foodGroupId)
        record.newValue =
          record.newValue && record.newValue.filter((foodGroupId) => foodGroupId !== foodGroupId)
        state.changeRecords[foodGroupChangeId] = record
      })
    },
    REMOVE_FOOD_GROUP(state, foodGroupId) {
      delete state.foodGroupRecords[foodGroupId]
    },
    REMOVE_STAGE(state, stageId) {
      delete state.stageRecords[stageId]
    },
  },
}
