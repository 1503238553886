import { Ability } from '@casl/ability'
import { uniq } from 'lodash-es'
export const ability = new Ability()
let permissions = []

const applyPermissions = (abilities) => {
  ability.update(abilities)
}

const resetPermissions = () => {
  ability.update([])
}

export const abilitySync = (store) => {
  // init empty list of permissions
  resetPermissions()

  return store.subscribe((mutation) => {
    switch (mutation.type) {
      case 'auth/SET_PERMISSIONS': {
        /**
         * Extremely ugly bug fix incoming
         * Because i didn't read CASL documentation properly, i did not know that the 'manage` keyword is an alias for all actions
         * This means that a user with manage role, can create, update and delete without specifying each individually
         * I have mapped both create and update to manage because we didn't have a separation in the past, except for delete
         * But because manage also includes delete, now we have to invert that permissions whenever we don't have it specified
         * Basically, if we don't get a delete permission for waste-log, we need to revert it so that casl knows that the user isn't allowed to do it.
         * @TODO refactor permissions accross the app and use create/update/delete individually
         */

        const hasWasteDeletePermissions = mutation.payload.find(
          ({ subject, action }) => subject === 'waste-log' && action === 'delete'
        )
        if (!hasWasteDeletePermissions) {
          permissions.push({ subject: 'waste-log', action: 'delete', inverted: true })
        }
        // added permissions at the end for any overwrites added by the following cases
        applyPermissions([...mutation.payload, ...permissions])
        break
      }
      case 'auth/UPDATE_USER_DATA':
      case 'auth/ME_SET_DATA': {
        permissions = []
        // @TODO remove this once backend returns correct permissions based on number of accessible menus
        if (
          store.getters['auth/user'].numberOfAccessibleMenus < 1 &&
          !ability.can('manage', 'menus')
        ) {
          permissions.push({ action: 'read', subject: 'menus', inverted: true })
        }
        // @TODO remove this once backend returns correct permissions for single/multi-site users
        if (!store.getters['auth/isMultiSiteUser']) {
          permissions.push({ action: 'read', subject: 'multi-site-dashboards', inverted: true })
        }
        // @TODO remove this once backend returns correct permissions for sso users
        if (!store.getters['auth/rolesString'] && store.getters['auth/isSSOUser']) {
          permissions.push({ action: 'read', subject: 'sso-welcome' })
        }
        applyPermissions([...ability.rules, ...permissions])
        break
      }
      // @TODO remove this once backend returns permissions for viewing videos
      case 'auth/organisations/STORE_ALLOWED_SITES': {
        const sites = store.getters['auth/organisations/sites']
        const packages = uniq(sites.map((site) => site.packageName))
        const permissions = [{ action: 'read', subject: 'training-general' }]
        packages.forEach((packageName) => {
          switch (packageName) {
            case 'Transform':
            case 'Outperform':
            case 'Accelerate':
            case 'VisionAI':
            case 'VisionAI+':
            case 'VisionAI+ (Full data validation)': {
              permissions.push({ action: 'read', subject: 'training-visionAI' })
              break
            }
            case 'Track':
            case 'Track (Trial)': {
              permissions.push({ action: 'read', subject: 'training-track' })
              break
            }
            case 'Sense':
            case 'VisionPW': {
              permissions.push({ action: 'read', subject: 'training-visionPW' })
              break
            }
            case 'Control':
            case 'VisionControl': {
              permissions.push({ action: 'read', subject: 'training-visionControl' })
            }
          }
        })
        applyPermissions([...ability.rules, ...permissions])
        break
      }
      case 'auth/LOGOUT': {
        // remove all permissions on logout
        resetPermissions()
        break
      }
    }
  })
}
