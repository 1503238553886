import { cloneDeep } from 'lodash-es'
import api from '@/store/api/menus'

export function changedKey({ id, type }) {
  return `${type}-${id}`
}

export default {
  namespaced: true,

  state: {
    backupRecords: {},
    records: {},
    list: [],
    changedRecords: {},
  },

  getters: {
    menuItemsByType: (state) => (type) => {
      let list = state.list.map((id) => state.records[id])
      return list.filter((item) => item.type === type)
    },
    translatedItems: (state) => Object.values(state.changedRecords),
    originalItem:
      (state) =>
      ({ id, type }) =>
        state.backupRecords[changedKey({ id, type })],
  },

  actions: {
    getMenuItems({ commit, state }, { menuId, type, query }) {
      return api.fetchTranslations({ menuId, type, query }).then(({ records, total }) => {
        // check if we have changes cached and overwrite the record with it
        commit('STORE_BACKUP_RECORDS', records)
        Object.values(records).forEach((record) => {
          let changed = state.changedRecords[changedKey(record)]
          if (changed) record.nameEnglish = changed.nameEnglish
          return record
        })
        commit('STORE_RECORDS', records)
        return { records, total }
      })
    },
    saveTranslations({ commit, state }, { menuId, translations }) {
      return api.postTranslations({ menuId, translations }).then(({ translationsRemaining }) => {
        commit('STORE_BACKUP_RECORDS', state.records)
        commit('RESET_CHANGES')
        return { translationsRemaining }
      })
    },
    finishTranslations({ commit, state }, { menuId }) {
      return api.postFinishTranslations({ menuId }).then((menu) => {
        commit('STORE_BACKUP_RECORDS', state.records)
        commit('RESET_CHANGES')
        return menu
      })
    },
    translateMenuItem({ commit }, item) {
      commit('UPDATE_TRANSLATION', item)
      commit('ADD_NEW_CHANGED_TRANSLATION', item)
    },
    revertTranslationToDefault({ commit }, item) {
      let revertRecord = { ...item, nameEnglish: item.defaultTranslation }
      commit('UPDATE_TRANSLATION', revertRecord)
      commit('ADD_NEW_CHANGED_TRANSLATION', revertRecord)
    },
  },

  mutations: {
    STORE_RECORDS(state, records) {
      state.records = { ...state.records, ...records }
      state.list = Object.keys(records)
    },
    STORE_BACKUP_RECORDS(state, records) {
      state.backupRecords = { ...state.backupRecords, ...cloneDeep(records) }
    },
    UPDATE_TRANSLATION(state, record) {
      let key = changedKey({ id: record.id, type: record.type })
      state.records[key] = { ...state.records[key], ...record }
    },
    ADD_NEW_CHANGED_TRANSLATION(state, record) {
      state.changedRecords[changedKey(record)] = record
    },
    RESET_CHANGES(state) {
      state.changedRecords = {}
      state.records = cloneDeep(state.backupRecords)
    },
  },
}
