import axios, { spitURL } from '../api-axios'
import { keyBy } from 'lodash-es'
import mapSystem from './mappings/get-system'
import mapSystemSettings from './mappings/get-system-settings'
import mapSettingsMetadata from '../api/mappings/get-settings-metadata'
export default {
  fetchSystems({ query, signal }) {
    return axios
      .get(spitURL('systems-management/v2/systems', query), { signal })
      .then((resp) => resp.data)
      .then((json) => ({
        records: keyBy(json.data.map(mapSystem), 'id'),
        componentRecords: keyBy(
          json.data.flatMap(({ components }) => components),
          'id'
        ),
        sortedRecordIds: json.data.map(({ id }) => id),
        total: json.metadata.total,
      }))
  },
  fetchSystem(id) {
    return axios
      .get(`systems-management/systems/${id}`)
      .then((resp) => resp.data)
      .then((system) => ({
        systemRecord: mapSystem(system),
        componentRecords: keyBy(system.components, 'id'),
      }))
  },
  patchSystem(id, body) {
    return axios
      .patch(`systems-management/systems/${id}`, body)
      .then((resp) => resp.data)
      .then(mapSystem)
  },
  fetchSystemSettings({ systemId, signal }) {
    return axios
      .get(`systems-management/systems/${systemId}/settings`, { signal })
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapSystemSettings)
      .then((settings) => keyBy(settings, 'key'))
  },
  patchSystemSettings(systemId, settings) {
    return axios
      .patch(`systems-management/systems/${systemId}/settings`, settings)
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapSystemSettings)
      .then((settings) => keyBy(settings, 'key'))
  },
  syncSystem(systemId) {
    return axios
      .post(`systems-management/systems/${systemId}/settings/sync`)
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapSystemSettings)
      .then((settings) => keyBy(settings, 'key'))
  },
  fetchSettingsMetadata() {
    return axios
      .get(
        spitURL('settings-metadata-management/settings-metadata', {
          filter: {
            availableFor: 'SYSTEM',
          },
        })
      )
      .then((response) => response.data)
      .then((json) => json.data)
      .then(mapSettingsMetadata)
      .then((settings) => keyBy(settings, 'inputKey'))
  },
  fetchSystemComponent(componentId) {
    return axios.get(`systems-management/components/${componentId}`).then((resp) => resp.data)
  },
  fetchSystemTypes() {
    return axios.get('systems-management/v2/systems/types').then((resp) => resp.data.data)
  },
}
