import { v4 as uuid } from '@lukeed/uuid'

export default function (items) {
  let foodItems = items.filter((item) => item.itemType === 'FOOD_ITEM')
  let foodGroups = items.filter((item) => item.itemType === 'FOOD_GROUP')
  let stages = items.filter((item) => item.itemType === 'STAGE')
  // add internal ids based on the dependency table
  foodItems = foodItems.map((foodItem) => {
    return {
      ...foodItem,
      internalId: foodItem.itemId || uuid(),
    }
  })
  foodGroups = foodGroups.map((foodGroup) => {
    return {
      ...foodGroup,
      internalId: foodGroup.itemId || uuid(),
    }
  })
  stages = stages.map((stage) => {
    return {
      ...stage,
      internalId: stage.itemId || uuid(),
    }
  })

  // get a flattened list of all the changes
  let allChanges = []
    .concat(foodItems, foodGroups, stages)
    .reduce((changes, item) => {
      // flatten the list of changes
      // - get individual change
      // - add a reference back to the original item
      // - add the change type (REFERENCES or STATIC)
      if (item.itemChanges && item.itemChanges.length) {
        return changes.concat(
          item.itemChanges.map((change) => ({
            itemId: item.internalId,
            itemType: item.itemType,
            type: getChangeType(change.field),
            action: item.action,
            ...change,
          }))
        )
      } else {
        return changes
      }
    }, [])
    .map((change, index) => {
      return {
        id: `${index + 1}`,
        itemId: change.itemId,
        itemType: change.itemType,
        key: change.field,
        type: change.type,
        action: change.action,
        oldValue: change.action === 'DELETE' ? change.value : change.oldValue,
        newValue: change.action === 'NEW' ? change.value : change.newValue,
      }
    })

  // turn the changes list into a records object
  let changeRecords = allChanges.reduce((records, change) => {
    records[change.id] = change
    return records
  }, {})

  let foodItemChanges = allChanges.filter(({ itemType }) => itemType === 'FOOD_ITEM')
  // turn the food items into a records object
  let foodItemRecords = foodItems.reduce((records, foodItem) => {
    records[foodItem.internalId] = {
      id: foodItem.internalId,
      itemId: foodItem.action === 'NEW' ? null : foodItem.itemId,
      action: foodItem.action,
      name: foodItem.itemName,
      // reference the items in the changes records
      changeIds: foodItemChanges
        .filter(
          (change) => change.itemId === foodItem.internalId && change.action === foodItem.action
        )
        .map((change) => change.id),
    }
    return records
  }, {})

  // get all the food groups referenced in the food item changes
  let foodGroupChanges = allChanges.filter(({ itemType }) => itemType === 'FOOD_GROUP')
  let foodGroupRecords = foodGroups
    .map((foodGroup) => {
      return {
        id: foodGroup.itemId,
        foodGroupId: foodGroup.action === 'NEW' ? null : foodGroup.itemId,
        name: foodGroup.itemName,
        action: foodGroup.action,
        changeIds: foodGroupChanges
          .filter(
            (change) => change.itemId === foodGroup.internalId && change.action === foodGroup.action
          )
          .map((change) => change.id),
      }
    })
    .reduce((records, foodGroup) => {
      records[foodGroup.id] = foodGroup
      return records
    }, {})

  // get all the stages referenced in the food item changes
  let stageChanges = allChanges.filter(({ itemType }) => itemType === 'STAGE')
  let stageRecords = stages
    .map((stage) => {
      return {
        id: stage.itemId,
        stageId: stage.action === 'NEW' ? null : stage.itemId,
        name: stage.itemName,
        action: stage.action,
        changeIds: stageChanges
          .filter((change) => change.itemId === stage.internalId && change.action === stage.action)
          .map((change) => change.id),
      }
    })
    .reduce((records, stage) => {
      records[stage.id] = stage
      return records
    }, {})

  return { foodItemRecords, stageRecords, foodGroupRecords, changeRecords }
}

function getChangeType(field) {
  // return the type of change the field handles
  // STATIC = normal static value, (name, cost etc.)
  // REFERENCES = links to other items (stages, food groups)
  return ['stages', 'foodGroups'].includes(field) ? 'REFERENCES' : 'STATIC'
}
