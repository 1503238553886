import { createI18n } from 'vue-i18n'
import { merge } from 'lodash-es'
import axios from '@/store/api-axios'
import messages from '@/languages/i18n/en.json'
import feCoreMessages from '@winnow/fe-core/dist/locales/en.json'
import extra from '@/languages/not-translated-en-GB.json'

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en: merge(messages, feCoreMessages, extra) },
  silentFallbackWarn: import.meta.env.DEV,
})

const loadedLanguages = ['en'] // our default language that is preloaded

const hubLanguageMapper = {
  'en-US': 'en',
  'en-GB': 'en',
  'zh-HANS': 'zh_CN',
}
const feCoreLanguageMapper = {
  'en-US': 'en',
  'en-GB': 'en',
  'zh-HANS': 'zh',
}
const appLanguageMapper = {
  'en-US': 'en',
  'en-GB': 'en',
  'zh-HANS': 'zh',
}
const setI18nLanguage = (lang) => {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = lang
  } else {
    i18n.global.locale.value = lang
  }
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(locale) {
  // `en-GB` or `zh-HANS` will turn into `en`, `zh`
  const hubLang = hubLanguageMapper[locale] || locale.split('-')[0]
  const feCoreLang = feCoreLanguageMapper[locale] || locale.split('-')[0]
  const appLang = appLanguageMapper[locale] || locale.split('-')[0]
  // If the same language
  if (i18n.locale === appLang) {
    return Promise.resolve(setI18nLanguage(appLang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(appLang)) {
    return Promise.resolve(setI18nLanguage(appLang))
  }

  // If the language hasn't been loaded yet
  return Promise.all([
    import(`@/languages/i18n/${hubLang}.json`),
    import(`../../node_modules/@winnow/fe-core/dist/locales/${feCoreLang}.json`),
  ]).then(([messages, feCoreMessages]) => {
    i18n.global.setLocaleMessage(appLang, merge(messages.default, feCoreMessages.default))
    loadedLanguages.push(appLang)
    return setI18nLanguage(appLang)
  })
}

export default i18n
