import { mapValues } from 'lodash-es'
export default (data) =>
  mapValues(
    {
      annualisedFoodCost: data.annualisedFoodCost,
      kitchenOperationId: data.kitchenOperationId,
      preparedFoodPercentageId: data.preparedFoodPercentageId,
      sectorId: data.sectorId,
      subSectorId: data.subSectorId,
    },
    (v) => (v === null ? '' : v)
  )
// converted null values to empty strings because vue changed the handling of null values
// when added to an <option> tag. It removes the value altogether and uses the innerText as the new value
// this means that if you have a disabled default value and it doesn't get changed before submitting the form,
// the new value will be whatever the text in the option was: 'Select an option' or whatever
