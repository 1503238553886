import { createApp } from 'vue'

import App from '@/App.vue'
import i18n from '@/languages'
import router from '@/router'
import { store } from '@/store'

import { abilitiesPlugin } from '@casl/vue'
import { ability } from '@/store/plugins/abilitySync'

import VueResize from 'vue-resize'
import FloatingVue from 'floating-vue'
import Clipboard from 'v-clipboard'
import vClickOutside from 'v-click-outside'
import Toast from 'vue-toastification'
import { filterBeforeCreate } from '@/utils/toast'
import ToastCloseIcon from '@/components/toast/ToastCloseIcon.vue'
import ToastIcon from '@/components/toast/ToastIcon.vue'
import { TOAST_DISMISS_DELAY } from '@/store/constants'
import VueScrollTo from 'vue-scrollto'

import LoadingSpinner from '@/components/_global/Spinner/Spinner.vue'
import 'virtual:svg-icons-register'
import SvgIcon from '@/components/_global/SvgIcon.vue'
import PageSection from '@/components/PageSection.vue'
import SectionHeader from '@/components/SectionHeader.vue'
import TestAttrs from '@/directives/test'
import { SetupCalendar } from 'v-calendar'
import * as Sentry from '@sentry/vue'

// @TODO enable this once the response headers have been added
// import { BrowserTracing } from '@sentry/tracing'

const app = createApp(App)

const SENTRY_SAMPLE_RATE_PRODUCTION = 0.2
const SENTRY_SAMPLE_RATE_NOT_PRODUCTION = 1.0
if (import.meta.env.VUE_APP_SENTRY_ENABLED === 'true') {
  // Initialise Sentry
  Sentry.init({
    app,
    dsn: 'https://4c55f5cb558d44a5b64d9b95f34705fb@o4504515106766848.ingest.sentry.io/4504519680851968',
    environment: import.meta.env.VUE_APP_SENTRY_ENV,
    integrations: [
      // @TODO enable these once the response headers have been added
      // @LINK https://docs.sentry.io/platforms/javascript/guides/react/performance/instrumentation/automatic-instrumentation/
      // new BrowserTracing({
      //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      //   tracePropagationTargets: [
      //     'auth-uat.winnow.tech',
      //     'hub-services-uat.winnow.tech',
      //     'analytics-services-uat.winnow.tech',
      //     'auth.winnowsolutions.com',
      //     'hub-services.winnowsolutions.com',
      //     'analytics-services.winnowsolutions.com',
      //     /^\//
      //   ]
      // })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    tracesSampleRate:
      import.meta.env.VUE_APP_SENTRY_ENV === 'production'
        ? SENTRY_SAMPLE_RATE_PRODUCTION
        : SENTRY_SAMPLE_RATE_NOT_PRODUCTION,
  })
}

app.use(FloatingVue, {
  popperTriggers: ['hover', 'click', 'touch', 'focus'],
  boundary: '.shell-body',
  // added here for now, will move to propper place when implementing new design
  themes: {
    tooltip: {
      triggers: ['hover', 'click', 'focus', 'touch'],
    },
    'tooltip-white': {
      $extend: 'tooltip',
      placement: 'top',
      $resetCss: false,
      html: true,
    },
    'tooltip-white-lg': {
      $extend: 'tooltip',
      placement: 'top',
      $resetCss: false,
      html: true,
    },
    'tooltip-danger': {
      $extend: 'tooltip',
      placement: 'top',
      $resetCss: false,
      html: true,
    },
    'tooltip-warning': {
      $extend: 'tooltip',
      placement: 'top',
      $resetCss: false,
      html: true,
    },
    'tooltip-neutral': {
      $extend: 'tooltip',
      placement: 'top',
      $resetCss: false,
      html: true,
    },
    'tooltip-success': {
      $extend: 'tooltip',
      placement: 'top',
      $resetCss: false,
      html: true,
    },
    'tooltip-info': {
      $extend: 'tooltip',
      placement: 'top',
      $resetCss: false,
      html: true,
    },
  },
})
app.use(VueResize)
app.use(abilitiesPlugin, ability, { useGlobalProperties: true })
app.use(Clipboard)
app.use(vClickOutside)
// temporary fix for working with vue3
const { bind, unbind } = vClickOutside.directive
app.directive('click-outside', {
  mounted(el, bindling) {
    bind(el, { value: bindling.value })
  },
  beforeUnmount(el) {
    unbind(el)
  },
})
// temporary fix />

app.use(VueScrollTo)

app.use(SetupCalendar)

app.component('LoadingSpinner', LoadingSpinner)
app.directive('test', TestAttrs)
app.component('SvgIcon', SvgIcon)
app.component('PageSection', PageSection)
app.component('SectionHeader', SectionHeader)
// Needed to automatically unwrap computed refs. Will become default in Vue 3.3
app.config.unwrapInjectedRef = true
export default function () {
  store.dispatch('auth/getMe').finally(() => {
    app.use(store)
    app.use(i18n)
    app.use(router)

    // needs to be started together with the router otherwise it goes into an infinite loop of redirects
    // when the router does not have user data
    // @TODO: Fix the router redirect logic
    app.use(Toast, {
      // Set the default options for all toast notifications
      closeButton: ToastCloseIcon,
      filterBeforeCreate,
      hideProgressBar: true,
      icon: ToastIcon,
      position: 'top-center',
      shareAppContext: true,
      timeout: TOAST_DISMISS_DELAY,
      transition: 'Vue-Toastification__fade',
      onMounted: (_, toastApp) => {
        // Register the router
        toastApp.use(router)
        toastApp.use(i18n)
      },
    })
    app.mount('#app')
    document.body.classList.remove('is-booting')
  })
}
