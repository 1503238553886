/**
 * @typedef {'BOOLEAN' | 'TEXT' | 'MULTI_OPTION' | 'SINGLE_OPTION'} InputType
 */

/**
 * @typedef {Object} ValidationRules
 * @property {boolean} [required] - Whether field is required
 * @property {number} [minValue] - Minimum allowed value
 * @property {number} [maxValue] - Maximum allowed value
 * @property {Array<number>} [between] - Range of allowed values [min, max]
 * @property {number} [minLength] - Minimum string length
 * @property {number} [maxLength] - Maximum string length
 * @property {boolean} [numeric] - Whether value must be numeric
 * @property {string} [pattern] - Regular expression pattern to match
 */

/**
 * @typedef {Object} SettingsInput
 * @property {string} key - The key identifier for the setting
 * @property {string} name - Display name of the setting
 * @property {string} description - Description of the setting
 * @property {string} type - Data type of the setting (boolean, int, etc.)
 * @property {Array} [choiceOptions] - Available options
 * @property {*} defaultValue - Default value for the setting
 * @property {Object} section - Section information
 * @property {string} section.id - Section identifier
 * @property {string} section.name - Section display name
 */

/**
 * @typedef {Object} DisabledCondition
 * @property {string} key - Key of the dependent setting
 * @property {*} value - Value that triggers disabled state
 */

/**
 * @typedef {Object} SettingSection
 * @property {string} id - Section identifier
 * @property {string} name - Section display name
 */

/**
 * @typedef {Object} Setting
 * @property {string} inputKey - The key identifier for the setting
 * @property {string} name - Display name of the setting
 * @property {string} description - Detailed description of the setting
 * @property {string} type - Data type of the setting
 * @property {InputType} inputType - Type of input control to be used
 * @property {Array} options - Available options for choice-based settings
 * @property {*} defaultValue - Default value for the setting
 * @property {string} useOptionsFrom - Dependencies configuration
 * @property {SettingSection} section - Section information
 * @property {DisabledCondition} disabledIf - Condition for disabling the setting
 * @property {Object} validations - Vuelidate validation rules
 */

/**
 * Maps settings keys to their corresponding input types based on type and predefined mappings
 * @param {string} key - The settings key to map
 * @param {string} type - The type of the setting ('boolean', 'int', etc)
 * @returns {InputType} The mapped input type
 */
const mapSettingToInputType = ({ key, type, choiceOptions }) => {
  if (type === 'boolean') return 'BOOLEAN'
  if (type === 'int' && choiceOptions?.length) return 'SINGLE_OPTION'
  if (type === 'int[]') return 'MULTI_OPTION'
  if (type === 'string' && choiceOptions?.length) return 'SINGLE_OPTION'
  if (type === 'string[]') return 'MULTI_OPTION'

  const inputTypeMap = {
    'installation.locale.available': 'MULTI_OPTION',
    'installation.locale.default': 'SINGLE_OPTION',
    'installation.eventrecording.policy': 'SINGLE_OPTION',
    'system.camera0.exposure_mode': 'SINGLE_OPTION',
    'system.camera0.focus_mode': 'SINGLE_OPTION',
    'installation.bin.type': 'SINGLE_OPTION',
    'installation.eventrecording.policy': 'SINGLE_OPTION',
  }
  return inputTypeMap[key]
}

/**
 * This is for fields that rely on options selected in another field
 *
 * @param {string} key - The settings key to map.
 * @returns {string|undefined} The dependency key if it exists in the mapping, undefined otherwise.
 */
const mapKeyToDependencies = (key) => {
  const dependencyMap = {
    'installation.locale.default': 'installation.locale.available',
  }
  return dependencyMap[key]
}

/**
 * This is a first attempt at disabling a field based on the value of another field
 *
 * In the following example, `target.field` will be DISABLED IF `referenced.field` has the value `TEST`
 * ```
 * 'target.field': {
 *   key: 'referenced.field',
 *   value: 'TEST',
 * },
 * ```
 *
 * @param {string} key - The configuration key to check for disabled conditions.
 * @returns {DisabledCondition|undefined} An object containing the dependency key and value that determines if the setting should be disabled.
 */
const mapKeyToDisabledCondition = (key) => {
  const dependencyMap = {
    'installation.image_pick.sam_threshold': {
      key: 'installation.bin.type',
      value: 'Open',
    },
    'system.camera0.exposure': {
      key: 'system.camera0.exposure_mode',
      value: 'AUTO',
    },
    'system.camera0.focus': {
      key: 'system.camera0.focus_mode',
      value: 'AUTO',
    },
    'installation.throwandgo.pw_treshold': {
      key: 'installation.throwandgo.pw_enabled',
      value: false,
    },
  }
  return dependencyMap[key]
}

/**
 * Locally defined validation rules for specific settings
 *
 * These will be coming from BE at some point
 * @param {String} key settings metadata identifier
 * @returns {ValidationRules} validation rules
 */
const mapSettingToValidation = (settingsItem) => {
  const key = settingsItem.key
  const validationMap = {
    'installation.locale.available': {
      required: true,
      maxLength: 3,
    },
    'installation.locale.default': {
      required: true,
    },
    'installation.predictions.autocategorise.limit': {
      minValue: 0,
      maxValue: 1,
    },
    'installation.image_pick.sam_threshold': {
      minValue: 0,
      maxValue: 1,
    },
    'installation.image_pick.motion_threshold': {
      minValue: 0,
      maxValue: 1,
    },
    'installation.appsync.interval': {
      minValue: 0,
      maxValue: 120,
    },
    'installation.admin_lock.code': {
      numeric: true,
      minLength: 4,
      maxLength: 4,
      pattern: '[0-9]{4,4}',
    },
    'installation.attention.motion_sensitivity': {
      minValue: 0,
      maxValue: 1,
    },
    'installation.predictions.ui.timeout': {
      minValue: 0,
      maxValue: 10000,
    },
    'installation.waste_recording.recording_timeout': {
      numeric: true,
      integer: true,
      minValue: 0,
      maxValue: 60,
    },
    'installation.waste_recording.confirmation_timeout': {
      numeric: true,
      integer: true,
      minValue: 0,
      maxValue: 60,
    },
    'installation.wastelog.retention': {
      numeric: true,
      integer: true,
      minValue: 0,
      maxValue: 30,
    },
    'installation.throwandgo.pw_treshold': {
      numeric: true,
      integer: true,
      minValue: 0,
      maxValue: 1000,
    },
    'system.scale.sensitivity': {
      minValue: 20,
      maxValue: 200,
      integer: true,
      numeric: true,
    },
    'system.led.brightness': {
      minValue: 0,
      maxValue: 100,
      integer: true,
      numeric: true,
    },
    'system.camera0.exposure': {
      minValue: 0,
      maxValue: 100,
      integer: true,
      numeric: true,
    },
    'system.camera0.focus': {
      minValue: 0,
      maxValue: 100,
      integer: true,
      numeric: true,
    },
  }
  const validations = validationMap[key] || {}
  switch (settingsItem.type) {
    case 'int':
      validations.integer = true
      break
    case 'float':
      validations.decimal = true
      break
  }
  return validations
}

const mapKeyToPermissions = (key) => {
  switch (key) {
    case 'installation.ai.enabled':
      return 'READ'
  }
  if (key.includes('installation.')) {
    return 'EDIT'
  }

  return 'READ'
}

/**
 * Maps settings data to a format suitable for the application's use
 * @param {Array<SettingsInput>} settings - Array of setting items to be transformed
 * @returns {Array<Setting>} Array of mapped setting objects
 */
export default function (settings) {
  return settings.map((settingsItem) => {
    return {
      inputKey: settingsItem.key,
      name: settingsItem.name,
      description: settingsItem.description,
      dataType: settingsItem.type,
      inputType: mapSettingToInputType(settingsItem),
      options: settingsItem.choiceOptions,
      defaultValue: settingsItem.defaultValue,
      useOptionsFrom: mapKeyToDependencies(settingsItem.key),
      section: {
        id: settingsItem.section.id,
        name: settingsItem.section.name,
      },
      disabledIf: mapKeyToDisabledCondition(settingsItem.key),
      validations: mapSettingToValidation(settingsItem),
      permissions: mapKeyToPermissions(settingsItem.key),
      ...mapSettingToValidation(settingsItem),
    }
  })
}
