import { isEmpty, omitBy, isUndefined } from 'lodash-es'

import api from '@/store/api/organisation'
import getOldValues from '@/store/utils/getOldValues'

const state = {
  records: {},
  packages: [],
  sectorProperties: {},
  meta: {
    levels: null,
  },
}

const getters = {
  byId: (state) => (id) => state.records[id],
  nodes: (state) => Object.values(state.records),
  levelsList: (state) =>
    Object.keys(state.meta.levels).map((level) => ({
      id: parseInt(level, 10),
      name: state.meta.levels[level],
    })),
  packages: (state) => state.packages,
  packageById: (state) => (packageId) => state.packages.find(({ id }) => id == packageId),
  sectorProperties: (state) => state.sectorProperties,
  getLevelName: (state) => (level) => state.meta.levels[level],
  getChildrenNodes:
    (state, { nodes }) =>
    (id) =>
      nodes.filter((node) => node.parentId === id),
  getRootOrganisationForNodeById: (state, getters) => (nodeId) => {
    const node = getters['byId'](nodeId)
    const parentNodeId = node.parentId
    const parentNode = getters['byId'](parentNodeId)
    if (node.level === 1) {
      return node
    } else if (parentNode.level === 1) {
      return parentNode
    } else return getters['getRootOrganisationForNodeById'](parentNodeId)
  },
  // these are all active L1 organisations currently stored
  getOrganisations: (state, getters) => {
    return getters.nodes.filter((node) => node.level == 1 && node.archived === false)
  },
}

const actions = {
  getTree({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      api
        .fetchTree(id)
        .then(({ records, metadata }) => {
          isEmpty(state.records)
            ? commit('STORE_RECORDS', records)
            : commit('UPDATE_RECORDS', records)

          commit('STORE_META', metadata)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getNode({ commit }, nodeId) {
    return api.fetchNode(nodeId).then((record) => {
      commit('STORE_RECORD', record)
    })
  },

  getPackages({ commit, state }) {
    if (state.packages.length) return Promise.resolve()
    return api
      .fetchPackages()
      .then((data) => {
        commit('STORE_PACKAGES', data)
      })
      .catch((error) => Promise.reject(error))
  },

  createNode({ commit }, { data, parentId }) {
    return new Promise((resolve, reject) => {
      api
        .postNode({ payload: data, parentId })
        .then((node) => {
          commit('STORE_RECORD', node)
          resolve(node)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  saveNodeChanges({ getters, commit }, { nodeId, data, query }) {
    return api
      .patchNode({
        nodeId,
        query,
        data: {
          old: getOldValues(getters.byId(nodeId), data),
          new: data,
        },
      })
      .then((node) => {
        commit('UPDATE_RECORD', node)
      })
  },

  moveNode({ getters, commit }, { id, newParentId }) {
    const { byId } = getters

    const oldModel = {
      id,
      parentId: byId(id).parentId,
    }

    const newModel = {
      id,
      parentId: newParentId,
    }

    return new Promise((resolve, reject) => {
      api
        // PUT
        .moveNode({ oldModel, newModel })
        .then(({ id, parentId }) => {
          const record = byId(id)

          commit('STORE_RECORD', { ...record, parentId })
          resolve(id)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getNodeSectorProperties({ commit }, nodeId) {
    return api.fetchNodeSectorProperties(nodeId).then(({ record, metadata }) => {
      commit('STORE_SECTOR_PROPERTIES', metadata)
      commit('UPDATE_RECORD', { id: nodeId, sectorProperties: record })
    })
  },
  saveNodeSectorPropertiesChanges({ commit, getters }, { nodeId, payload }) {
    return api
      .putNodeSectorProperties({
        nodeId,
        payload: {
          old: getOldValues(getters.byId(nodeId).sectorProperties, payload),
          new: payload,
        },
      })
      .then((data) => {
        commit('UPDATE_RECORD', { id: nodeId, sectorProperties: data })
      })
  },
  getNodeSendingControls({ commit }, nodeId) {
    return api.fetchNodeSendingControls(nodeId).then(({ record }) => {
      commit('UPDATE_RECORD', { id: nodeId, sendingControls: record })
    })
  },
  saveNodeSendingControlsDomains({ commit, getters }, { nodeId, payload }) {
    const { approvedDomains, emails } = getters.byId(nodeId).sendingControls

    return api
      .putNodeSendingControls({
        nodeId,
        payload: {
          old: {
            approvedDomains,
            emails,
          },
          new: {
            approvedDomains: payload,
            emails,
          },
        },
      })
      .then((data) => {
        commit('UPDATE_RECORD', { id: nodeId, sendingControls: data })
      })
  },
  saveNodeSendingControlsEmails({ commit, getters }, { nodeId, payload }) {
    const { approvedDomains, emails } = getters.byId(nodeId).sendingControls

    return api
      .putNodeSendingControls({
        nodeId,
        payload: {
          old: {
            approvedDomains,
            emails,
          },
          new: {
            approvedDomains,
            emails: payload,
          },
        },
      })
      .then((data) => {
        commit('UPDATE_RECORD', { id: nodeId, sendingControls: data })
      })
  },
  getNodeDailyReportsSubscribers({ commit }, nodeId) {
    return api.fetchNodeDailyReportsSubscribers(nodeId).then(({ record, metadata }) => {
      const { isReportEnabled } = metadata
      commit('UPDATE_RECORD', {
        id: nodeId,
        dailyReportsSubscribers: { list: record, isReportEnabled },
      })
    })
  },
  saveNodeDailyReportsSubscribers({ commit, getters }, { nodeId, payload }) {
    const { dailyReportsSubscribers } = getters.byId(nodeId)
    return api
      .putNodeDailyReportsSubscribers({
        nodeId,
        payload: {
          old: dailyReportsSubscribers && dailyReportsSubscribers.list,
          new: payload,
        },
      })
      .then((data) => {
        commit('UPDATE_RECORD', {
          id: nodeId,
          dailyReportsSubscribers: { ...dailyReportsSubscribers, list: data },
        })
      })
  },
  getNodeSummaryReportsSubscribers({ commit }, nodeId) {
    return api.fetchNodeSummaryReportsSubscribers(nodeId).then(({ record, metadata }) => {
      const { isReportEnabled } = metadata
      commit('UPDATE_RECORD', {
        id: nodeId,
        summaryReportsSubscribers: { list: record, isReportEnabled },
      })
    })
  },
  saveNodeSummaryReportsSubscribers({ commit, getters }, { nodeId, payload }) {
    const { summaryReportsSubscribers } = getters.byId(nodeId)
    return api
      .putNodeSummaryReportsSubscribers({
        nodeId,
        payload: {
          old: summaryReportsSubscribers && summaryReportsSubscribers.list,
          new: payload,
        },
      })
      .then((data) => {
        commit('UPDATE_RECORD', {
          id: nodeId,
          summaryReportsSubscribers: { ...summaryReportsSubscribers, list: data },
        })
      })
  },
  getNodeCrossSiteReportsSubscribers({ commit }, nodeId) {
    return api.fetchNodeCrossSiteReportsSubscribers(nodeId).then(({ record, metadata }) => {
      const { isReportEnabled } = metadata
      commit('UPDATE_RECORD', {
        id: nodeId,
        crossSiteReportsSubscribers: { list: record, isReportEnabled },
      })
    })
  },
  saveNodeCrossSiteReportsSubscribers({ commit, getters }, { nodeId, payload }) {
    const { crossSiteReportsSubscribers } = getters.byId(nodeId)
    return api
      .putNodeCrossSiteReportsSubscribers({
        nodeId,
        payload: {
          old: crossSiteReportsSubscribers && crossSiteReportsSubscribers.list,
          new: payload,
        },
      })
      .then((data) => {
        commit('UPDATE_RECORD', {
          id: nodeId,
          crossSiteReportsSubscribers: { ...crossSiteReportsSubscribers, list: data },
        })
      })
  },
  getNodeTransactionsExtractSubscribers({ commit }, nodeId) {
    return api.fetchNodeTransactionsExtractSubscribers(nodeId).then(({ record, metadata }) => {
      const { isReportEnabled } = metadata
      commit('UPDATE_RECORD', {
        id: nodeId,
        transactionsExtractSubscribers: { list: record, isReportEnabled },
      })
    })
  },
  saveNodeTransactionsExtractSubscribers({ commit, getters }, { nodeId, payload }) {
    const { transactionsExtractSubscribers } = getters.byId(nodeId)
    return api
      .putNodeTransactionsExtractSubscribers({
        nodeId,
        payload: {
          old: transactionsExtractSubscribers && transactionsExtractSubscribers.list,
          new: payload,
        },
      })
      .then((data) => {
        commit('UPDATE_RECORD', {
          id: nodeId,
          transactionsExtractSubscribers: { ...transactionsExtractSubscribers, list: data },
        })
      })
  },
  getNodeDailyReportsConfigs({ commit }, nodeId) {
    return api.fetchNodeDailyReportsConfigs(nodeId).then((data) => {
      commit('UPDATE_RECORD', {
        id: nodeId,
        dailyReportsConfigs: data,
      })
    })
  },
  saveNodeDailyReportsConfigs({ commit, getters }, { nodeId, payload }) {
    const { dailyReportsConfigs } = getters.byId(nodeId)
    return api
      .patchNodeDailyReportsConfigs({
        nodeId,
        payload: {
          old: getOldValues(dailyReportsConfigs, payload),
          new: payload,
        },
      })
      .then((data) => {
        commit('UPDATE_RECORD', {
          id: nodeId,
          dailyReportsConfigs: data,
        })
      })
  },
  getNodeSummaryReportsConfigs({ commit }, nodeId) {
    return api.fetchNodeSummaryReportsConfigs(nodeId).then((data) => {
      commit('UPDATE_RECORD', {
        id: nodeId,
        summaryReportsConfigs: data,
      })
    })
  },
  saveNodeSummaryReportsConfigs({ commit, getters }, { nodeId, payload }) {
    const { summaryReportsConfigs } = getters.byId(nodeId)
    return api
      .patchNodeSummaryReportsConfigs({
        nodeId,
        payload: {
          old: getOldValues(summaryReportsConfigs, payload),
          new: payload,
        },
      })
      .then((data) => {
        commit('UPDATE_RECORD', {
          id: nodeId,
          summaryReportsConfigs: data,
        })
      })
  },
  getNodeTransactionsExtractConfigs({ commit }, nodeId) {
    return api.fetchNodeTransactionsExtractConfigs(nodeId).then((data) => {
      commit('UPDATE_RECORD', {
        id: nodeId,
        transactionsExtractConfigs: data,
      })
    })
  },
  createNodeTransactionsExtractConfigs({ commit }, { nodeId, payload }) {
    return api
      .postNodeTransactionsExtractConfigs({
        nodeId,
        payload,
      })
      .then((data) => {
        commit('UPDATE_RECORD', {
          id: nodeId,
          transactionsExtractConfigs: data,
        })
      })
  },
  saveNodeTransactionsExtractConfigs({ commit, getters }, { nodeId, payload }) {
    const { transactionsExtractConfigs } = getters.byId(nodeId)
    return api
      .patchNodeTransactionsExtractConfigs({
        nodeId,
        payload: {
          old: getOldValues(transactionsExtractConfigs, payload),
          new: payload,
        },
      })
      .then((data) => {
        commit('UPDATE_RECORD', {
          id: nodeId,
          transactionsExtractConfigs: data,
        })
      })
  },
  getNodeReportingParams({ commit }, nodeId) {
    return api.fetchNodeReportingParameters(nodeId).then((data) => {
      commit('UPDATE_RECORD', {
        id: nodeId,
        reportingParams: data,
      })
    })
  },
  saveNodeReportingParams({ commit, getters }, { nodeId, payload }) {
    const { reportingParams } = getters.byId(nodeId)
    return api
      .patchNodeReportingParameters({
        nodeId,
        payload: {
          old: getOldValues(reportingParams, payload),
          new: payload,
        },
      })
      .then((data) => {
        commit('UPDATE_RECORD', {
          id: nodeId,
          reportingParams: data,
        })
      })
  },
}

const mutations = {
  STORE_RECORDS(state, newRecords) {
    state.records = { ...state.records, ...newRecords }
  },

  STORE_RECORD(state, newRecord) {
    state.records[newRecord.id] = newRecord
  },

  UPDATE_RECORDS(state, records) {
    Object.values(records).forEach((record) => {
      let cleanRecord = omitBy(record, isUndefined)
      state.records[record.id] = {
        ...state.records[record.id],
        ...cleanRecord,
      }
    })
  },

  UPDATE_RECORD(state, record) {
    let cleanRecord = omitBy(record, isUndefined)
    state.records[record.id] = {
      ...state.records[record.id],
      ...cleanRecord,
    }
  },

  STORE_SECTOR_PROPERTIES(state, sectorProperties) {
    state.sectorProperties = sectorProperties
  },

  STORE_PACKAGES(state, packages) {
    state.packages = packages
  },

  STORE_META(state, meta) {
    state.meta = { ...state.meta, ...meta }
  },

  ADD_USER_TO_RECORD(state, { orgId, userId }) {
    state.records[orgId].userIds.push(userId)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
