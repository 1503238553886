import axios from '../../api-axios'
import organisations from './organisations'
import { cloneDeep, keyBy, camelCase } from 'lodash-es'
import mapJobTitle from '../../api/mappings/get-job-title'
import mapPermissions from '../../api/mappings/get-permissions'
import { WINNOW_ORG_ID, WINNOW_GLOBAL_ADMIN, WINNOW_L2_SUPPORT } from '../../../store/constants'
const state = {
  keycloak: null,
  isAuthenticated: false,
  me: {
    user: {
      email: '',
      firstName: '',
      lastName: '',
      fullName: '',
      id: '',
      numberOfAccessibleSites: 0,
      subjectOrganization: '',
      subjectOrganisationFullName: '',
      username: '',
      groups: [],
    },
    userSettings: {
      locale: {
        key: null,
        code: 'en-GB',
      },
      weight: {
        key: null,
        code: 'Metric',
        value: null,
      },
      temperature: {
        key: null,
        code: 'c',
        value: null,
      },
      preferredMenuLanguage: {
        key: null,
        code: 'LOCAL_NAME',
        value: null,
      },
    },
    userMetadata: {
      isSetupDone: true,
      ssoUser: false,
    },
  },
  userPermissions: {},
  error: false,
  authPromise: null,
  settings: {
    locale: [],
    weight: [],
    temperature: [],
    preferredMenuLanguage: [],
  },
  jobTitles: {},
}

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  userId: (state) => state.me.user.id,
  user: (state) => state.me.user,
  userFullName: (state) => {
    let { firstName, lastName, fullName } = state.me.user
    if (fullName) return fullName
    else {
      return [firstName, lastName].filter((val) => val).join(' ')
    }
  },
  userSettings: (state) => state.me.userSettings,
  userMetadata: (state) => state.me.userMetadata,
  // state.me.user.groups might be undefined if you're running vue dev tools
  rolesString: (state) => state.me.user.groups.join(', '),
  settings: (state) => state.settings,
  isSetupDone: (state) => state.me.userMetadata.isSetupDone,
  isWinnowUser: (state) => state.me.user.subjectOrganization === WINNOW_ORG_ID,
  locale: (state) => state.me.userSettings.locale.code,
  preferredMenuLocale: (state) =>
    state.me.userSettings.preferredMenuLanguage.code === 'SYSTEM_TRANSLATION'
      ? state.me.userSettings.locale.code
      : undefined,
  keycloakUserId: (state) => state.keycloak.subject,
  isAdmin: (state) => state.me.user.groups.includes(WINNOW_GLOBAL_ADMIN),
  isL2Support: (state) => state.me.user.groups.includes(WINNOW_L2_SUPPORT),
  error: (state) => state.error,
  isSingleSiteUser: (state) => state.me.user.numberOfAccessibleSites === 1,
  isMultiSiteUser: (state) => state.me.user.numberOfAccessibleSites > 1,
  // a user type that has access to multi site dashboards but doesn't have access to individual sites
  isReseller: (state) => state.me.user.numberOfAccessibleSites === 0,
  weightUnit: (state) => state.me.userSettings.weight.code,
  userRootOrganisationId: (state) => state.me.user.subjectOrganization,
  jobTitles: (state) => Object.values(state.jobTitles),
  isSSOUser: (state) => state.me.userMetadata.ssoUser,
}

const actions = {
  logout({ state }) {
    const redirectUri = import.meta.env.VUE_APP_KEYCLOAK_LOGOUT_REDIRECT_URL
    const opts = redirectUri ? { redirectUri } : {}

    state.keycloak.logout(opts)
  },
  getMe({ dispatch, commit }) {
    return Promise.all([dispatch('getProfile'), dispatch('getPermissions')]).catch((error) => {
      commit('ME_SET_ERROR', error)
      throw error
    })
  },
  getProfile({ commit }) {
    return axios
      .get('/user-management/profile')
      .then((response) => response.data)
      .then((json) => json.data)
      .then((profile) => {
        commit('ME_SET_DATA', profile)
        let jobTitle = profile.user.jobTitle
        if (!state.jobTitles[jobTitle]) {
          commit('STORE_JOB_TITLE', {
            title: jobTitle,
            description: jobTitle,
            jobKey: camelCase(jobTitle),
          })
        }
      })
  },
  getPermissions({ commit, state }) {
    return axios
      .get('authorization/authz/resources/permissions')
      .then((response) => response.data)
      .then((json) => {
        const permissionsData = mapPermissions(json.data.permissions, state.me.user.groups)
        commit('SET_PERMISSIONS', permissionsData)
      })
  },
  getSettings({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/user-management/settings')
        .then((response) => {
          commit('SETTINGS_SET_DATA', response.data.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getJobTitles({ commit }) {
    return axios
      .get('/user-management/user-jobs')
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then((jobs) => jobs.map(mapJobTitle))
      .then((jobs) => {
        let jobTitles = keyBy(jobs, 'title')
        commit('STORE_JOB_TITLES', jobTitles)
      })
  },
  saveSettings({ commit }, payload) {
    return axios
      .put('/user-management/profile', {
        old: cloneDeep({
          user: state.me.user,
          userSettings: state.me.userSettings,
          userMetadata: state.me.userMetadata,
        }),
        new: cloneDeep(payload),
      })
      .then((response) => {
        commit('ME_SET_ONLY_USER_DATA', response.data.data)
        commit('ME_SET_LOCALE', payload.userSettings.locale)
      })
  },
}

const mutations = {
  SET_DATA(state, keycloak, isAuthenticated = false, refreshInterval) {
    state.keycloak = keycloak
    state.isAuthenticated = isAuthenticated
    clearInterval(refreshInterval)
  },
  SET_PERMISSIONS(state, permissions) {
    state.userPermissions = permissions
  },
  ME_SET_DATA(state, payload) {
    state.me = {
      ...payload,
      userSettings: {
        ...payload.userSettings,
        // save default en-GB locale if no default setting for language
        locale: payload.userSettings.locale || state.me.userSettings.locale,
      },
    }
  },

  // This updates the state with data coming back when saving profile data.
  // Problem is, some of the data comes back as null because it's an external resource
  // and the BE wants to avoid making another call only for those properties...i think.
  // We "fix" this by keeping the current data from the store for those specific properties.
  ME_SET_ONLY_USER_DATA(state, payload) {
    const { subjectOrganization, groups, numberOfAccessibleSites, subjectOrganisationFullName } =
      state.me.user

    state.me = {
      ...payload,
      user: {
        ...payload.user,
        subjectOrganization,
        subjectOrganisationFullName,
        groups,
        numberOfAccessibleSites,
      },
    }
  },
  UPDATE_USER_DATA(state, { user }) {
    state.me.user = {
      ...state.me.user,
      ...user,
    }
  },
  ME_SET_LOCALE(state, locale) {
    state.me.userSettings.locale = locale
  },
  ME_SETUP_DONE(state, flag) {
    state.me.userMetadata.isSetupDone = flag
  },
  ME_SET_ERROR(state, error) {
    state.error = error
  },
  SETTINGS_SET_DATA(state, payload) {
    let merged = {}

    // sigh
    payload.forEach((obj) => {
      merged = { ...merged, ...obj }
    })

    state.settings = merged

    if (!state.me.userSettings.locale.key) {
      // add the `key` property to the default english language because we only hardcoded the `code` property
      let english = merged.locale.find((lang) => lang.code === state.me.userSettings.locale.code)
      state.me.userSettings.locale = {
        key: english.key,
        code: english.code,
      }
    }
  },
  STORE_JOB_TITLE(state, jobTitle) {
    state.jobTitles[jobTitle.title] = jobTitle
  },
  STORE_JOB_TITLES(state, payload) {
    state.jobTitles = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    organisations,
  },
}
