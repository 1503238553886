import { merge } from 'lodash-es'
import api from '@/store/api/systems-management'
import installationApi from '@/store/api/installations-management'
export default {
  namespaced: true,
  state: {
    records: {},
    settingsRecords: {},
    componentRecords: {},
    sortedRecordIds: [],
    totalRecordsAvailable: 0,
    settingsMetadataRecords: {},
    systemTypes: [],
  },
  getters: {
    byId: (state) => (id) => ({
      ...state.records[id],
      components: state.records[id]?.componentIds.map((id) => state.componentRecords[id]) || [],
    }),
    sortedSystems: (state, getters) => state.sortedRecordIds.map((id) => getters['byId'](id)),
    getSystemSettings: (state) => (id) =>
      state.settingsRecords[id] ? Object.values(state.settingsRecords[id]) : [],
    totalRecordsAvailable: (state) => state.totalRecordsAvailable,
    systemInstallationById: (state, getters, rootState, rootGetters) => {
      return (systemId) => {
        const system = getters['byId'](systemId)
        return system && rootGetters['installations-management/byId'](system.installationId)
      }
    },
    settingsMetadata: (state) => state.settingsMetadataRecords,
    componentsForSystemId: (state, getters) => (systemId) => {
      return getters['byId'](systemId)?.components || []
    },
    componentTypeForSystemId: (state, getters) => (systemId, componentType) => {
      return getters['componentsForSystemId'](systemId)?.find((component) => {
        return component.type === componentType
      })
    },
    connectionStatusForSystemId: (state, getters) => (id) => {
      const IoTCore = getters['componentTypeForSystemId'](id, 'IOTCORE')
      if (!IoTCore || IoTCore.metadata?.connected == undefined) return 'UNAVAILABLE'
      return IoTCore.metadata?.connected ? 'CONNECTED' : 'DISCONNECTED'
    },
    systemTypes: (state) => state.systemTypes,
  },
  actions: {
    getSystems({ commit }, { query, signal }) {
      return api
        .fetchSystems({ query, signal })
        .then(({ records, sortedRecordIds, total, componentRecords }) => {
          commit('STORE_RECORDS', records)
          commit('STORE_SORT_ORDER', sortedRecordIds)
          commit('UPDATE_TOTAL_RECORDS', total)
          commit('STORE_COMPONENT_RECORDS', componentRecords)
        })
    },
    getSystem({ commit }, id) {
      return api.fetchSystem(id).then(({ systemRecord, componentRecords }) => {
        commit('STORE_RECORDS', { [systemRecord.id]: systemRecord })
        commit('STORE_COMPONENT_RECORDS', componentRecords)
      })
    },
    getSystemSettings({ commit }, { systemId, signal }) {
      return api.fetchSystemSettings({ systemId, signal }).then((records) => {
        commit('STORE_SYSTEM_SETTINGS_RECORDS', { systemId, records })
      })
    },
    updateSystemSettings(store, { systemId, settings }) {
      return api.patchSystemSettings(systemId, settings)
    },
    buildSystem({ commit }, id) {
      return api.patchSystem(id, { type: 'ARCHON' }).then((system) => {
        commit('STORE_RECORDS', { [system.id]: system })
      })
    },
    changeAuthorisation({ commit }, { id, authorised }) {
      return api.patchSystem(id, { authorised }).then((system) => {
        commit('STORE_RECORDS', { [system.id]: system })
      })
    },
    renameSystem({ commit }, { id, name }) {
      return api.patchSystem(id, { configuration: { name } }).then((system) => {
        commit('STORE_RECORDS', { [system.id]: system })
      })
    },
    linkInstallation({ commit }, { systemId, installationId }) {
      return installationApi.linkSystem({ systemId, installationId }).then(() => {
        commit('STORE_RECORDS', {
          [systemId]: {
            installationId: installationId,
          },
        })
      })
    },
    unlinkInstallation({ commit }, { systemId, installationId }) {
      return installationApi.unlinkSystem({ systemId, installationId }).then(() => {
        commit('STORE_RECORDS', {
          [systemId]: {
            installationId: null,
          },
        })
      })
    },
    syncSystem({ commit }, systemId) {
      return api.syncSystem(systemId).then((records) => {
        commit('STORE_SYSTEM_SETTINGS_RECORDS', { systemId, records })
      })
    },
    getSettingsMetadata({ commit }) {
      return api.fetchSettingsMetadata().then((settingsRecords) => {
        if (settingsRecords['installation.locale.default']) {
          settingsRecords['installation.locale.default'].options = [
            ...settingsRecords['installation.locale.available'].options,
          ]
        }
        commit('STORE_SETTINGS_METADATA', settingsRecords)
      })
    },
    getComponent({ commit }, componentId) {
      return api.fetchSystemComponent(componentId).then((componentRecord) => {
        commit('STORE_COMPONENT_RECORD', componentRecord)
      })
    },
    getSystemTypes({ commit }) {
      return api.fetchSystemTypes().then((systemTypes) => {
        commit('STORE_SYSTEM_TYPES', systemTypes)
      })
    },
  },
  mutations: {
    STORE_RECORDS(state, records) {
      state.records = merge(state.records, records)
    },
    STORE_SORT_ORDER(state, ids) {
      state.sortedRecordIds = ids
    },
    STORE_COMPONENT_RECORD(state, componentRecord) {
      state.componentRecords[componentRecord.id] = componentRecord
    },
    STORE_COMPONENT_RECORDS(state, records) {
      state.componentRecords = merge(state.componentRecords, records)
    },
    UPDATE_TOTAL_RECORDS(state, count) {
      state.totalRecordsAvailable = count
    },
    STORE_SYSTEM_SETTINGS_RECORDS(state, { systemId, records }) {
      state.settingsRecords[systemId] = records
    },
    STORE_SETTINGS_METADATA(state, records) {
      state.settingsMetadataRecords = merge(state.settingsMetadataRecords, records)
    },
    STORE_SYSTEM_TYPES(state, systemTypes) {
      state.systemTypes = systemTypes
    },
  },
}
