import { createStore } from 'vuex'

import { abilitySync } from './plugins/abilitySync'

import auth from './modules/auth'
import waste from './modules/waste-log'
import transactions from './modules/transactions'
import taxonomy from './modules/taxonomy'
import CS from './modules/covers-sales'
import hierarchy from './modules/hierarchy'
import menuManagement from './modules/menu-management'
import codeBuilder from './modules/code-builder'
import imageCache from './modules/image-cache'
import users from './modules/users'
import sitesCollections from './modules/sites-collections'
import systemsManagement from './modules/systems-management'
import installationsManagement from './modules/installations-management'

export const store = createStore({
  modules: {
    auth: auth,
    'waste-log': waste,
    transactions,
    taxonomy,
    hierarchy,
    users,
    'covers-sales': CS,
    'menu-management': menuManagement,
    'code-builder': codeBuilder,
    'image-cache': imageCache,
    'sites-collections': sitesCollections,
    'systems-management': systemsManagement,
    'installations-management': installationsManagement,
  },
  plugins: [abilitySync],
})
