import api from '@/store/api/installations-management'
import { merge } from 'lodash-es'
export default {
  namespaced: true,
  state: () => ({
    records: {},
    settingsRecords: {},
    organisationRecords: {},
    sortedRecordIds: [],
    totalRecordsAvailable: 0,
    settingsMetadataRecords: {},
  }),
  getters: {
    byId: (state) => (id) => state.records[id],
    settingsForInstallationId: (state) => (id) => state.settingsRecords[id],
    settingsListForInstallationId: (state) => (id) =>
      Object.values(state.settingsRecords[id] || {}),
    systemLinksForInstallationId: (state) => (id) => state.records[id].systemLinks,
    sortedInstallations: (state, getters) => state.sortedRecordIds.map((id) => getters['byId'](id)),
    totalRecordsAvailable: (state) => state.totalRecordsAvailable,
    organisations: (state) => Object.values(state.organisationRecords),
    organisationById: (state) => (id) => state.organisationRecords[id],
    settingsMetadata: (state) => state.settingsMetadataRecords,
  },
  actions: {
    createInstallation({ commit }, payload) {
      return api.postInstallation(payload).then((installation) => {
        commit('STORE_INSTALLATION_RECORDS', { [installation.id]: installation })
        return installation
      })
    },
    getInstallations({ commit }, query) {
      return api.fetchInstallations(query).then(({ records, sortedIds, total }) => {
        commit('STORE_INSTALLATION_RECORDS', records)
        commit('STORE_INSTALLATIONS_ORDER', sortedIds)
        commit('UPDATE_TOTAL_RECORDS', total)
      })
    },
    getInstallation({ commit, state }, installationId) {
      return api.fetchInstallation(installationId).then((installation) => {
        commit('STORE_INSTALLATION_RECORDS', { [installation.id]: installation })
      })
    },
    getInstallationSettings({ commit, state }, installationId) {
      return api.fetchInstallationSettings(installationId).then((records) => {
        commit('STORE_INSTALLATION_SETTINGS_RECORDS', {
          installationId,
          records,
        })
      })
    },
    saveInstallation({ commit }, installation) {
      return api.patchInstallation(installation).then((installation) => {
        commit('STORE_INSTALLATION_RECORDS', { [installation.id]: installation })
      })
    },
    getSettingsMetadata({ commit }) {
      return api.fetchSettingsMetadata().then((settingsRecords) => {
        commit('STORE_SETTINGS_METADATA', settingsRecords)
      })
    },
    updateSettings({ commit }, { id, data }) {
      return api.patchSettings({ id, data }).then((settingsRecords) => {
        commit('STORE_INSTALLATION_SETTINGS_RECORDS', {
          installationId: id,
          records: settingsRecords,
        })
      })
    },
  },
  mutations: {
    STORE_INSTALLATION_RECORDS(state, records) {
      state.records = merge(state.records, records)
    },
    STORE_INSTALLATIONS_ORDER(state, sortedIds) {
      state.sortedRecordIds = sortedIds
    },
    UPDATE_TOTAL_RECORDS(state, count) {
      state.totalRecordsAvailable = count
    },
    STORE_ORGANISATION_RECORDS(state, records) {
      state.organisationRecords = merge(state.organisationRecords, records)
    },
    STORE_INSTALLATION_SETTINGS_RECORDS(state, { installationId, records }) {
      state.settingsRecords = merge(state.settingsRecords, { [installationId]: records })
    },
    STORE_SETTINGS_METADATA(state, records) {
      state.settingsMetadataRecords = { ...state.settingsMetadataRecords, ...records }
    },
  },
}
