import api from '@/store/api/organisation'
import getOldValues from '@/store/utils/getOldValues'
import { merge } from 'lodash-es'
export default {
  namespaced: true,
  state: {
    records: {},
    sortedRecordIds: [],
    organisationRecords: {},
    siteRecords: {},
  },
  getters: {
    byId:
      (state, getters) =>
      (id, { withSites } = {}) => {
        const record = state.records[id]
        return {
          ...record,
          organisation: state.organisationRecords[record.organisationId],
          sites: withSites ? getters['sitesById'](record.siteIds) : undefined,
        }
      },
    sortedCollections: (state, getters) => state.sortedRecordIds.map((id) => getters['byId'](id)),
    sites: (state) => Object.values(state.siteRecords),
    sitesById: (state) => (legacyIds) =>
      legacyIds.map((legacyIds) => state.siteRecords[legacyIds]).filter((site) => !!site),
    organisations: (state) => Object.values(state.organisationRecords),
    organisationById: (state) => (id) => state.organisationRecords[id],
  },
  actions: {
    getSitesCollections({ commit }, query) {
      return api
        .fetchSitesCollections(query)
        .then(({ records, organisationRecords, sortedIds, metadata }) => {
          commit('STORE_RECORDS', records)
          commit('STORE_ORGANISATION_RECORDS', organisationRecords)
          commit('STORE_SORTED_IDS', sortedIds)
          return { metadata }
        })
    },
    getOrganisations({ commit }) {
      return api.fetchCollectionsOrganisations().then(({ records }) => {
        commit('STORE_ORGANISATION_RECORDS', records)
      })
    },
    getSitesCollection({ commit }, { collectionId }) {
      return api.fetchSitesCollection({ collectionId }).then(({ record, organisationRecord }) => {
        commit('STORE_RECORD', { record, organisationRecord })
        return { record }
      })
    },
    getSites({ commit, state }, { collectionId }) {
      return api
        .fetchSites({ rootId: state.records[collectionId].organisationId })
        .then(({ records }) => {
          commit('STORE_SITE_RECORDS', records)
        })
    },
    createSitesCollection({ commit }, { organisationId, name }) {
      return api
        .postSitesCollection({ organisationId, name })
        .then(({ record, organisationRecord }) => {
          commit('STORE_RECORD', { record, organisationRecord })
          return record
        })
    },
    updateSitesCollection({ commit, getters }, { collectionId, data }) {
      return api
        .patchSitesCollection({
          collectionId,
          data: {
            old: getOldValues(getters.byId(collectionId), data),
            new: data,
          },
        })
        .then(({ record, organisationRecord }) => {
          commit('STORE_RECORD', { record, organisationRecord })
          return record
        })
    },
  },
  mutations: {
    STORE_RECORDS(state, records) {
      state.records = records
    },
    STORE_ORGANISATION_RECORDS(state, records) {
      state.organisationRecords = merge(state.organisationRecords, records)
    },
    STORE_SORTED_IDS(state, ids) {
      state.sortedRecordIds = ids
    },
    STORE_RECORD(state, { record, organisationRecord }) {
      state.records[record.id] = record
      if (organisationRecord) {
        state.organisationRecords[organisationRecord.id] = organisationRecord
      }
    },
    STORE_SITE_RECORDS(state, records) {
      state.siteRecords = records
    },
  },
}
