import { createRouter, createWebHistory } from 'vue-router'

import RouteContainer from '@/components/RouteContainer.vue'
import { store } from '@/store'
import { ability } from '@/store/plugins/abilitySync'
import { wasteLogParamsToProps } from '@/router/utils'
import { pickLevelOrgName } from '@/store/utils/organisations'
import { useSelectedSite } from '@/composables/useSelectedSite'
import { useAnalytics } from './composables/useAnalytics'

// NOTE
// Commented out in-app login parts
// To be potentially brought back

/**
 * Permissions for route access
 *
 * Every route, child route or anything in between MUST have a meta with permissions defined in it
 *
 * There are a few generic routes that can be accessed by almost all users except Tracker Licenses who should not be accessing Hub at all.
 * If a child route does not have an explicit permission requirement, the parent permissions MUST be copied to the child route.
 *
 * @TODO: Figure out a better way of handling child permission for routes.
 */
const router = createRouter({
  base: import.meta.env.BASE_URL,
  history: createWebHistory(),
  scrollBehavior(to, from, position) {
    if (to.matched.some((m) => m.meta.saveScroll)) {
      return position
    }

    return { left: 0, top: 0 }
  },
  linkActiveClass: 'is-active',
  routes: [
    {
      path: '/',
      redirect: '/home',
      meta: {
        title: 'Winnow Hub',
      },
      requiredAbility: {
        subject: 'generic',
        action: 'read',
      },
    },
    // {
    //   path: '/login',
    //   name: 'login',
    //   component: Pages.LoginPage,
    //   beforeEnter: (to, from, next) => {
    //     if (store.getters['auth/isAuthenticated']) {
    //       next('/home')
    //       return
    //     }

    //     next()
    //   }
    // },
    {
      path: '/setup',
      name: 'setup',
      component: () => import('@/pages/Setup/Setup.vue'),
      meta: {
        title: 'Winnow Hub Setup',
        requiredAbility: {
          subject: 'generic',
          action: 'read',
        },
      },
    },
    {
      path: '/job-title',
      name: 'job-title',
      component: () => import('@/pages/Setup/JobTitle.vue'),
      meta: {
        title: 'Winnow HUB Job Title Selection',
        requiredAbility: {
          subject: 'generic',
          action: 'read',
        },
      },
    },
    {
      path: '/sso-welcome',
      name: 'sso-welcome',
      component: () => import('@/pages/SSOWelcome/SSOWelcome.vue'),
      meta: {
        title: 'Welcome to Winnow',
        requiredAbility: {
          subject: 'sso-welcome',
          action: 'read',
        },
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/pages/Home/Home.vue'),
      meta: {
        title: 'Winnow Hub',
        requiredAbility: {
          subject: 'generic',
          action: 'read',
        },
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/pages/Profile/Profile.vue'),
      meta: {
        title: 'Winnow Hub - Profile',
      },
      requiredAbility: {
        subject: 'generic',
        action: 'read',
      },
    },
    {
      path: '/waste-log/:siteId?',
      name: 'waste-log',
      component: () => import('@/pages/WasteLog/WasteLog.vue'),
      meta: {
        title: 'Winnow Hub - Waste Log',
        saveScroll: true,
        requiredAbility: {
          subject: 'waste-log',
          action: 'read',
        },
      },
      redirect: {
        name: 'waste-log-list',
      },
      props: wasteLogParamsToProps,
      children: [
        {
          path: 'list',
          name: 'waste-log-list',
          component: () => import('@/pages/WasteLog/List/WLListView.vue'),
          meta: {
            title: 'Winnow Hub - Waste Log - List',
            saveScroll: true,
            requiredAbility: {
              subject: 'waste-log',
              action: 'read',
            },
          },
          props: wasteLogParamsToProps,
        },
        {
          path: 'grid',
          name: 'waste-log-grid',
          component: () => import('@/pages/WasteLog/Grid/WLGridView.vue'),
          meta: {
            title: 'Winnow Hub - Waste Log - Gallery',
            saveScroll: true,
            requiredAbility: {
              subject: 'waste-log',
              action: 'read',
            },
          },
          props: wasteLogParamsToProps,
        },
      ],
    },
    {
      path: '/waste-log/:siteId?/timeline',
      component: () => import('@/pages/WasteTimeline/WasteTimelineView.vue'),
      meta: {
        title: 'Winnow Hub - Waste Log - Timeline',
        saveScroll: true,
        requiredAbility: {
          subject: 'waste-log',
          action: 'read',
        },
      },
      children: [
        {
          path: '',
          name: 'waste-timeline',
          component: () => import('@/pages/WasteTimeline/WasteTimeline.vue'),
          meta: {
            title: 'Winnow Hub - Waste Log - Timeline',
            saveScroll: true,
            requiredAbility: {
              subject: 'waste-log',
              action: 'read',
            },
          },
        },
      ],
    },
    {
      path: '/covers-sales/:siteId?',
      name: 'covers-sales',
      component: () => import('@/pages/CoversSales/CoversSales.vue'),
      meta: {
        title: 'Winnow Hub - Covers & Sales',
        requiredAbility: {
          subject: 'covers-sales',
          action: 'read',
        },
      },
    },
    {
      path: '/taxonomy',
      name: 'taxonomy',
      component: () => import('@/pages/TaxMan/TaxMan.vue'),
      meta: {
        title: 'Winnow Hub - Taxonomy Manager',
        requiredAbility: {
          action: 'read',
          subject: 'taxonomy',
        },
      },
      redirect: {
        name: 'term-index',
      },
      children: [
        {
          path: 'terms',
          component: RouteContainer,
          meta: {
            requiredAbility: {
              action: 'read',
              subject: 'taxonomy',
            },
          },
          children: [
            {
              path: '',
              name: 'term-index',
              component: () => import('@/pages/TaxMan/Terms/TermIndex/TermIndex.vue'),
              meta: {
                title: 'Winnow Hub - Taxonomy Manager - Terms',
                requiredAbility: {
                  action: 'read',
                  subject: 'taxonomy',
                },
              },
            },
            {
              path: 'new/:parentId',
              name: 'new-term',
              component: () => import('@/pages/TaxMan/Terms/NewTerm/NewTerm.vue'),
              meta: {
                title: 'Winnow Hub - Taxonomy Manager - New term',
                requiredAbility: {
                  action: 'manage',
                  subject: 'taxonomy',
                },
              },
            },
            {
              path: 'edit/:termId',
              name: 'edit-term',
              component: () => import('@/pages/TaxMan/Terms/EditTerm/EditTerm.vue'),
              meta: {
                title: 'Winnow Hub - Taxonomy Manager - Edit term',
                requiredAbility: {
                  action: 'manage',
                  subject: 'taxonomy',
                },
              },
            },
          ],
        },
        {
          path: 'facets',
          component: RouteContainer,
          meta: {
            requiredAbility: {
              action: 'read',
              subject: 'taxonomy',
            },
          },
          children: [
            {
              path: '',
              name: 'facet-index',
              component: () => import('@/pages/TaxMan/Facets/FacetIndex/FacetIndex.vue'),
              meta: {
                title: 'Winnow Hub - Taxonomy Manager - Facets',
                requiredAbility: {
                  action: 'read',
                  subject: 'taxonomy',
                },
              },
            },
            {
              path: 'new/:parentId',
              name: 'new-facet',
              component: () => import('@/pages/TaxMan/Facets/NewFacet/NewFacet.vue'),
              meta: {
                title: 'Winnow Hub - Taxonomy Manager - New facet',
                requiredAbility: {
                  action: 'manage',
                  subject: 'taxonomy',
                },
              },
            },
            {
              path: 'edit/:facetId',
              name: 'edit-facet',
              component: () => import('@/pages/TaxMan/Facets/EditFacet/EditFacet.vue'),
              meta: {
                title: 'Winnow Hub - Taxonomy Manager - Edit facet',
                requiredAbility: {
                  action: 'manage',
                  subject: 'taxonomy',
                },
              },
            },
          ],
        },
      ],
    },
    {
      path: '/menu-mapping',
      name: 'menu-mapping',
      component: () => import('@/pages/MenuMapping/MenuMapping.vue'),
      meta: {
        title: 'Winnow Hub - Menu Mapping',
        requiredAbility: {
          action: 'read',
          subject: 'menu-mapping',
        },
      },
      redirect: {
        name: 'menu-mapping-terms',
      },
      children: [
        {
          path: 'terms',
          name: 'menu-mapping-terms',
          component: () => import('@/pages/MenuMapping/Terms.vue'),
          meta: {
            title: 'Winnow Hub - Menu Mapping - Terms',
            saveScroll: true,
            requiredAbility: {
              action: 'read',
              subject: 'menu-mapping',
            },
          },
        },
        {
          path: 'facets',
          name: 'menu-mapping-facets',
          component: () => import('@/pages/MenuMapping/Facets.vue'),
          meta: {
            title: 'Winnow Hub - Menu Mapping - Facets',
            saveScroll: true,
            requiredAbility: {
              action: 'read',
              subject: 'menu-mapping',
            },
          },
        },
      ],
    },
    {
      path: '/single-site-dashboards/:siteId?',
      name: 'single-site-dashboards',
      component: () => import('@/pages/SingleSiteDashboards/SingleSiteDashboards.vue'),
      meta: {
        title: `Winnow Hub - ${
          store.getters['auth/isMultiSiteUser'] ? 'Single-kitchen dashboards' : 'Insights'
        }`,
        requiredAbility: {
          action: 'read',
          subject: 'single-site-dashboards',
        },
      },
    },
    {
      path: '/multi-site-dashboards',
      name: 'multi-site-dashboards',
      component: () => import('@/pages/MultiSiteDashboards/MultiSiteDashboards.vue'),
      meta: {
        title: 'Winnow Hub - Multi-kitchen dashboards',
        requiredAbility: {
          action: 'read',
          subject: 'multi-site-dashboards',
        },
      },
    },
    {
      path: '/downloads',
      name: 'downloads',
      component: () => import('@/pages/Downloads/Downloads.vue'),
      meta: {
        title: 'Winnow Hub - Downloads',
        requiredAbility: {
          action: 'read',
          subject: 'downloads',
        },
      },
    },
    {
      path: '/menus',
      redirect: {
        name: 'menus-list',
      },
      name: 'menus',
      meta: {
        title: 'Winnow Hub - Menus',
        requiredAbility: {
          action: 'read',
          subject: 'menus',
        },
      },
      children: [
        {
          path: '',
          name: 'menus-list',
          component: () => import('@/pages/Menus/MenusList.vue'),
          meta: {
            requiredAbility: {
              action: 'read',
              subject: 'menus',
            },
          },
        },
        {
          path: 'create-menu',
          name: 'create-menu',
          component: () => import('@/pages/Menus/MenuCreate.vue'),
          meta: {
            requiredAbility: {
              action: 'manage',
              subject: 'menus',
            },
          },
        },
        {
          path: ':menuId',
          component: () => import('@/pages/Menus/Menu.vue'),
          props: true,
          meta: {
            requiredAbility: {
              action: 'read',
              subject: 'menus',
            },
          },
          children: [
            {
              path: 'summary',
              name: 'menu-summary',
              props: true,
              component: () => import('@/pages/Menus/MenuSummary.vue'),
              meta: {
                breadcrumb: 'Menu Summary',
                requiredAbility: {
                  action: 'read',
                  subject: 'menus',
                },
              },
            },
            {
              path: 'food-items',
              name: 'food-items',
              props: true,
              component: () => import('@/pages/Menus/FoodItems/FoodItems.vue'),
              redirect: {
                name: 'food-items-list',
              },
              meta: {
                requiredAbility: {
                  action: 'read',
                  subject: 'menus',
                },
              },
              children: [
                {
                  name: 'food-items-list',
                  path: 'list',
                  props: true,
                  component: () => import('./pages/Menus/FoodItems/FoodItemsList.vue'),
                  meta: {
                    breadcrumb: 'Food items list',
                    requiredAbility: {
                      action: 'read',
                      subject: 'menus',
                    },
                  },
                },
                {
                  name: 'food-items-grid',
                  path: 'grid',
                  props: true,
                  component: () => import('./pages/Menus/FoodItems/FoodItemsGrid.vue'),
                  meta: {
                    breadcrumb: 'Food items gallery',
                    requiredAbility: {
                      action: 'read',
                      subject: 'menus',
                    },
                  },
                },
              ],
            },
            {
              path: 'menu-issues',
              name: 'menu-issues',
              props: true,
              component: () => import('./pages/Menus/MenuIssues.vue'),
              meta: {
                breadcrumb: 'Menu Issues',
                requiredAbility: {
                  action: 'manage',
                  subject: 'menus',
                },
              },
              children: [
                {
                  path: 'cost-missing',
                  name: 'cost-missing',
                  component: () => import('./pages/Menus/MenuIssues/MenuFoodItemsCostNull.vue'),
                  props: true,
                  meta: {
                    breadcrumb: 'Missing cost food items',
                    requiredAbility: {
                      action: 'manage',
                      subject: 'menus',
                    },
                  },
                },
                {
                  path: 'cost-zero',
                  name: 'cost-zero',
                  component: () => import('./pages/Menus/MenuIssues/MenuFoodItemsCostZero.vue'),
                  props: true,
                  meta: {
                    breadcrumb: 'Zero cost food items',
                    requiredAbility: {
                      action: 'manage',
                      subject: 'menus',
                    },
                  },
                },
                {
                  path: 'portion-missing',
                  name: 'portion-missing',
                  component: () =>
                    import('./pages/Menus/MenuIssues/MenuFoodItemsPortionMissing.vue'),
                  props: true,
                  meta: {
                    breadcrumb: 'Missing portion food items',
                    requiredAbility: {
                      action: 'manage',
                      subject: 'menus',
                    },
                  },
                },
                {
                  path: 'duplicate-mappings',
                  name: 'menu-duplicate-mappings',
                  props: true,
                  component: () => import('./pages/Menus/MenuIssues/MenuDuplicateMappings.vue'),
                  meta: {
                    breadcrumb: 'Menu mapping duplicates',
                    requiredAbility: {
                      action: 'manage',
                      subject: 'menus',
                    },
                  },
                },
                {
                  path: 'parent-child-conflicts',
                  name: 'menu-parent-child-conflicts',
                  props: true,
                  component: () => import('./pages/Menus/MenuIssues/MenuParentChildConflicts.vue'),
                  meta: {
                    breadcrumb: 'Menu mapping parent/child conflicts',
                    requiredAbility: {
                      action: 'manage',
                      subject: 'menus',
                    },
                  },
                },
              ],
            },
            {
              path: 'tablet-editor',
              name: 'tablet-editor',
              props: true,
              component: () => import('@/pages/Menus/TabletEditor.vue'),
              meta: {
                breadcrumb: 'On Device',
                requiredAbility: {
                  action: 'read',
                  subject: 'menus',
                },
              },
              redirect: {
                name: 'tablet-editor-stages',
              },
              children: [
                {
                  path: 'stages',
                  component: RouteContainer,
                  meta: {
                    requiredAbility: {
                      action: 'read',
                      subject: 'menus',
                    },
                  },
                  children: [
                    {
                      path: '',
                      name: 'tablet-editor-stages',
                      props: true,
                      component: () =>
                        import('@/pages/Menus/TabletEditor/TabletEditorStagesList.vue'),
                      meta: {
                        breadcrumb: 'Stages',
                        requiredAbility: {
                          action: 'read',
                          subject: 'menus',
                        },
                      },
                    },
                    {
                      path: 'create',
                      name: 'tablet-editor-stage-create',
                      props: true,
                      component: () => import('@/pages/Menus/TabletEditor/TabletEditorStage.vue'),
                      meta: {
                        breadcrumb: 'Create stage',
                        requiredAbility: {
                          action: 'manage',
                          subject: 'menus',
                        },
                      },
                    },
                    {
                      path: ':stageId',
                      name: 'tablet-editor-stage',
                      props: true,
                      component: () => import('@/pages/Menus/TabletEditor/TabletEditorStage.vue'),
                      meta: {
                        breadcrumb: 'Stage',
                        requiredAbility: {
                          action: 'manage',
                          subject: 'menus',
                        },
                      },
                    },
                  ],
                },
                {
                  path: 'stage/:stageId/food-groups',
                  component: RouteContainer,
                  meta: {
                    requiredAbility: {
                      action: 'read',
                      subject: 'menus',
                    },
                  },
                  children: [
                    {
                      path: '',
                      name: 'tablet-editor-food-groups',
                      props: true,
                      component: () =>
                        import('@/pages/Menus/TabletEditor/TabletEditorFoodGroupsList.vue'),
                      meta: {
                        breadcrumb: 'Food Groups',
                        requiredAbility: {
                          action: 'read',
                          subject: 'menus',
                        },
                      },
                    },
                    {
                      path: 'create',
                      name: 'tablet-editor-food-group-create',
                      props: true,
                      component: () =>
                        import('@/pages/Menus/TabletEditor/TabletEditorFoodGroup.vue'),
                      meta: {
                        breadcrumb: 'Create food group',
                        requiredAbility: {
                          action: 'manage',
                          subject: 'menus',
                        },
                      },
                    },
                    {
                      path: ':foodGroupId',
                      name: 'tablet-editor-food-group',
                      props: true,
                      component: () =>
                        import('@/pages/Menus/TabletEditor/TabletEditorFoodGroup.vue'),
                      meta: {
                        breadcrumb: 'Food group',
                        requiredAbility: {
                          action: 'manage',
                          subject: 'menus',
                        },
                      },
                    },
                  ],
                },
                {
                  path: 'stage/:stageId/food-group/:foodGroupId/food-items',
                  name: 'tablet-editor-food-items',
                  props: true,
                  component: () => import('@/pages/Menus/TabletEditor/TabletEditorFoodItems.vue'),
                  meta: {
                    breadcrumb: 'Food items',
                    requiredAbility: {
                      action: 'read',
                      subject: 'menus',
                    },
                  },
                },
              ],
            },
            {
              path: 'menu-deploy',
              name: 'menu-deploy',
              props: true,
              meta: {
                breadcrumb: 'Menu deploy',
                requiredAbility: {
                  action: 'manage',
                  subject: 'menus',
                },
              },
              component: () => import('@/pages/Menus/MenuDeploy.vue'),
            },
            {
              path: 'translate-menu',
              name: 'translate-menu',
              props: true,
              component: () => import('@/pages/Menus/TranslateMenuV2/TranslateMenuV2.vue'),
              meta: {
                breadcrumb: 'Menu translations',
                requiredAbility: {
                  action: 'manage',
                  subject: 'menus',
                },
              },
            },
            {
              path: 'bulk-update',
              name: 'menu-bulk-update',
              props: true,
              component: () => import('@/pages/Menus/BulkUpdate.vue'),
              redirect: {
                name: 'bulk-update-food-items',
              },
              meta: {
                breadcrumb: 'Bulk update',
                requiredAbility: {
                  action: 'manage',
                  subject: 'menus',
                },
              },
              children: [
                {
                  path: 'food-items',
                  name: 'bulk-update-food-items',
                  props: true,
                  component: () => import('@/pages/Menus/BulkUpdate/BulkUpdateFoodItems.vue'),
                  meta: {
                    breadcrumb: 'Review food items',
                    requiredAbility: {
                      action: 'manage',
                      subject: 'menus',
                    },
                  },
                },
                {
                  path: 'stages',
                  name: 'bulk-update-stages',
                  props: true,
                  component: () => import('@/pages/Menus/BulkUpdate/BulkUpdateStages.vue'),
                  meta: {
                    breadcrumb: 'Review stages',
                    requiredAbility: {
                      action: 'manage',
                      subject: 'menus',
                    },
                  },
                },
                {
                  path: 'food-groups',
                  name: 'bulk-update-food-groups',
                  props: true,
                  component: () => import('@/pages/Menus/BulkUpdate/BulkUpdateFoodGroups.vue'),
                  meta: {
                    breadcrumb: 'Review food groups',
                    requiredAbility: {
                      action: 'manage',
                      subject: 'menus',
                    },
                  },
                },
                {
                  path: 'upload',
                  name: 'bulk-update-upload',
                  props: true,
                  component: () => import('@/pages/Menus/BulkUpdate/BulkUpdateUpload.vue'),
                  meta: {
                    breadcrumb: 'Upload new changes',
                    requiredAbility: {
                      action: 'manage',
                      subject: 'menus',
                    },
                  },
                },
              ],
            },
            {
              path: 'backdate',
              name: 'backdate',
              props: true,
              component: () => import('@/pages/Menus/MenuBackdate.vue'),
              meta: {
                breadcrumb: 'Backdate',
                requiredAbility: {
                  action: 'manage',
                  subject: 'menus',
                },
                title: 'Winnow HUB - Menus - Backdate',
              },
              redirect: {
                name: 'backdate-selection',
              },
              children: [
                {
                  path: '',
                  name: 'backdate-selection',
                  props: true,
                  component: () => import('@/pages/Menus/Backdate/MenuBackdateSelection.vue'),
                  meta: {
                    requiredAbility: {
                      action: 'manage',
                      subject: 'menus',
                    },
                  },
                },
                {
                  path: 'status',
                  name: 'backdate-status',
                  props: true,
                  component: () => import('@/pages/Menus/Backdate/MenuBackdateStatus.vue'),
                  meta: {
                    requiredAbility: {
                      action: 'manage',
                      subject: 'menus',
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/food-item',
      name: 'food-item',
      redirect: {
        name: 'food-mapping-similar',
      },
      meta: {
        requiredAbility: {
          action: 'read',
          subject: 'menus',
        },
      },
      props: true,
      component: () => import('@/pages/FoodItem/FoodItem.vue'),
      children: [
        {
          name: 'food-create',
          path: 'create',
          component: () => import('@/pages/FoodItem/FoodItemCreate.vue'),
          meta: {
            breadcrumb: 'New food item',
            requiredAbility: {
              action: 'manage',
              subject: 'menus',
            },
          },
        },
        {
          name: 'food-editor',
          path: ':foodItemId/editor',
          component: () => import('@/pages/FoodItem/FoodItemEditor.vue'),
          props: true,
          meta: {
            breadcrumb: 'Food item editor',
            requiredAbility: {
              action: 'read',
              subject: 'menus',
            },
          },
        },
        {
          name: 'food-groups',
          path: ':foodItemId/food-groups',
          component: () => import('@/pages/FoodItem/FoodItemGroups.vue'),
          props: true,
          meta: {
            breadcrumb: 'Food groups',
            requiredAbility: {
              action: 'manage',
              subject: 'menus',
            },
          },
        },
        {
          path: ':foodItemId/mapping',
          name: 'food-mapping',
          component: () => import('@/pages/FoodItem/FoodMapping.vue'),
          props: true,
          redirect: {
            name: 'food-mapping-similar',
          },
          meta: {
            requiredAbility: {
              action: 'manage',
              subject: 'menus',
            },
          },
          children: [
            {
              path: 'similar',
              name: 'food-mapping-similar',
              component: () => import('@/pages/FoodItem/FoodMappingSimilar.vue'),
              props: true,
              meta: {
                breadcrumb: 'Taxonomy mapping similar',
                requiredAbility: {
                  action: 'manage',
                  subject: 'menus',
                },
              },
            },
            {
              path: 'taxonomy',
              name: 'food-mapping-taxonomy',
              component: () => import('@/pages/FoodItem/FoodMappingTaxonomy.vue'),
              props: true,
              meta: {
                breadcrumb: 'Taxonomy mapping code builder',
                requiredAbility: {
                  action: 'manage',
                  subject: 'menus',
                },
              },
              redirect: {
                name: 'food-item-mapping-terms',
              },
              children: [
                {
                  path: 'terms',
                  name: 'food-item-mapping-terms',
                  component: () => import('@/pages/MenuMapping/Terms.vue'),
                  meta: {
                    title: 'Winnow Hub - Food Item Mapping - Terms',
                    saveScroll: true,
                    requiredAbility: {
                      action: 'manage',
                      subject: 'menus',
                    },
                  },
                },
                {
                  path: 'facets',
                  name: 'food-item-mapping-facets',
                  component: () => import('@/pages/MenuMapping/Facets.vue'),
                  meta: {
                    title: 'Winnow Hub - Food Item Mapping - Facets',
                    saveScroll: true,
                    requiredAbility: {
                      action: 'manage',
                      subject: 'menus',
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/hierarchy',
      name: 'hierarchy',
      component: () => import('@/pages/Hierarchy/Hierarchy.vue'),
      meta: {
        title: 'Winnow Hub - Organisations Hierarchy',
        saveScroll: true,
        requiredAbility: {
          action: 'manage',
          subject: 'hierarchy',
        },
      },
      props: true,

      children: [
        {
          path: ':nodeId',
          name: 'hierarchy-node',
          component: () => import('@/pages/Hierarchy/HierarchyNode.vue'),
          props: true,
          redirect: {
            name: 'hierarchy-node-overview',
          },
          meta: {
            requiredAbility: {
              action: 'manage',
              subject: 'hierarchy',
            },
          },
          children: [
            {
              path: 'overview',
              name: 'hierarchy-node-overview',
              component: () => import('@/pages/Hierarchy/HierarchyNode/HierarchyNodeOverview.vue'),
              props: true,
              meta: {
                saveScroll: true,
                requiredAbility: {
                  action: 'manage',
                  subject: 'hierarchy',
                },
              },
            },
            {
              path: 'users',
              name: 'hierarchy-node-users',
              component: () => import('@/pages/Hierarchy/HierarchyNode//HierarchyNodeUsers.vue'),
              props: true,
              meta: {
                saveScroll: true,
                requiredAbility: {
                  action: 'manage',
                  subject: 'hierarchy',
                },
              },
              redirect: {
                name: 'hierarchy-node-users-viewing',
              },
              children: [
                {
                  path: 'node-users',
                  name: 'hierarchy-node-users-node',
                  props: true,
                  meta: {
                    saveScroll: true,
                    requiredAbility: {
                      action: 'manage',
                      subject: 'hierarchy',
                    },
                  },
                  component: () =>
                    import(
                      '@/pages/Hierarchy/HierarchyNode/HierarchyNodeUsers/HierarchyNodeUsersNode.vue'
                    ),
                },
                {
                  path: 'node-users-viewing',
                  name: 'hierarchy-node-users-viewing',
                  props: true,
                  meta: {
                    saveScroll: true,
                    requiredAbility: {
                      action: 'manage',
                      subject: 'hierarchy',
                    },
                  },
                  component: () =>
                    import(
                      '@/pages/Hierarchy/HierarchyNode/HierarchyNodeUsers/HierarchyNodeUsersViewing.vue'
                    ),
                },
                {
                  path: 'settings',
                  name: 'hierarchy-node-users-settings',
                  props: true,
                  meta: {
                    saveScroll: true,
                    requiredAbility: {
                      action: 'manage',
                      subject: 'hierarchy',
                    },
                  },
                  component: () =>
                    import(
                      '@/pages/Hierarchy/HierarchyNode//HierarchyNodeUsers/HierarchyNodeUsersSettings.vue'
                    ),
                },
                {
                  path: 'archived-users',
                  name: 'hierarchy-node-users-archived',
                  props: true,
                  meta: {
                    saveScroll: true,
                    requiredAbility: {
                      action: 'manage',
                      subject: 'hierarchy',
                    },
                  },
                  component: () =>
                    import(
                      '@/pages/Hierarchy/HierarchyNode//HierarchyNodeUsers/HierarchyNodeUsersArchived.vue'
                    ),
                },
              ],
            },
            {
              path: 'reporting',
              name: 'hierarchy-node-reporting',
              component: () => import('@/pages/Hierarchy/HierarchyNode/HierarchyNodeReporting.vue'),
              props: true,
              meta: {
                saveScroll: true,
                requiredAbility: {
                  action: 'manage',
                  subject: 'hierarchy',
                },
              },
              redirect: {
                name: 'hierarchy-node-reporting-general-settings',
              },
              children: [
                {
                  path: 'general-settings',
                  name: 'hierarchy-node-reporting-general-settings',
                  component: () =>
                    import(
                      '@/pages/Hierarchy/HierarchyNode/HierarchyNodeReporting/ReportingGeneralSettings.vue'
                    ),
                  props: true,
                  meta: {
                    saveScroll: true,
                    requiredAbility: {
                      action: 'manage',
                      subject: 'hierarchy',
                    },
                  },
                },
                {
                  path: 'subscribers',
                  name: 'hierarchy-node-reporting-subscribers',
                  component: () =>
                    import(
                      '@/pages/Hierarchy/HierarchyNode/HierarchyNodeReporting/ReportingSubscribers.vue'
                    ),
                  props: true,
                  meta: {
                    saveScroll: true,
                    requiredAbility: {
                      action: 'manage',
                      subject: 'hierarchy',
                    },
                  },
                },
                {
                  path: 'configurations',
                  name: 'hierarchy-node-reporting-configurations',
                  component: () =>
                    import(
                      '@/pages/Hierarchy/HierarchyNode/HierarchyNodeReporting/ReportingConfigurations.vue'
                    ),
                  props: true,
                  meta: {
                    saveScroll: true,
                    requiredAbility: {
                      action: 'manage',
                      subject: 'hierarchy',
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/site-collections',
      redirect: '/kitchen-collections',
    },
    {
      path: '/site-collections/:collectionId',
      redirect: (to) => `/kitchen-collections/${to.params.collectionId}`,
    },
    {
      path: '/kitchen-collections',
      name: 'kitchen-collections',
      component: () => import('@/pages/SiteCollections/SiteCollections.vue'),
      meta: {
        title: 'Winnow Hub - Kitchen Collections',
        saveScroll: true,
        requiredAbility: {
          action: 'manage',
          subject: 'site-collections',
        },
      },
      redirect: {
        name: 'kitchen-collections-list',
      },
      children: [
        {
          path: '',
          name: 'kitchen-collections-list',
          component: () => import('@/pages/SiteCollections/SiteCollectionsList.vue'),
          saveScroll: true,
          requiredAbility: {
            action: 'manage',
            subject: 'site-collections',
          },
        },
        {
          path: 'create',
          name: 'kitchen-collections-create',
          component: () => import('@/pages/SiteCollections/SiteCollectionsCreate.vue'),
          saveScroll: true,
          requiredAbility: {
            action: 'manage',
            subject: 'site-collections',
          },
          meta: {
            title: 'Winnow Hub - Create Kitchens Collection',
          },
        },
        {
          path: ':collectionId',
          name: 'kitchen-collections-edit',
          component: () => import('@/pages/SiteCollections/SiteCollectionsEdit.vue'),
          saveScroll: true,
          requiredAbility: {
            action: 'manage',
            subject: 'site-collections',
          },
          props: true,
          meta: {
            title: 'Winnow Hub - Edit Kitchens Collection',
          },
        },
      ],
    },
    {
      path: '/systems-management',
      name: 'systems-management',
      component: () => import('@/pages/SystemsManagement/SystemsManagement.vue'),
      meta: {
        title: 'Winnow Hub - Systems',
        saveScroll: true,
        requiredAbility: {
          action: 'manage',
          subject: 'systems-management',
        },
      },
      redirect: {
        name: 'systems-management-list',
      },
      children: [
        {
          path: '',
          name: 'systems-management-list',
          component: () => import('@/pages/SystemsManagement/SystemsManagementList.vue'),
          saveScroll: true,
          meta: {
            requiredAbility: {
              action: 'manage',
              subject: 'systems-management',
            },
          },
        },
        {
          path: ':systemId',
          name: 'system-edit',
          props: true,
          component: () => import('@/pages/SystemsManagement/SystemEdit.vue'),
          meta: {
            requiredAbility: {
              action: 'manage',
              subject: 'systems-management',
            },
          },
          redirect: {
            name: 'system-summary',
          },
          children: [
            {
              path: 'summary',
              name: 'system-summary',
              props: true,
              meta: {
                title: 'Winnow Hub - System Summary',
                requiredAbility: {
                  action: 'manage',
                  subject: 'systems-management',
                },
              },
              component: () => import('@/pages/SystemsManagement/SystemSummary.vue'),
            },
            {
              path: 'components',
              name: 'system-components',
              props: true,
              meta: {
                title: 'Winnow Hub - System Components',
                requiredAbility: {
                  action: 'manage',
                  subject: 'systems-management',
                },
              },
              component: () => import('@/pages/SystemsManagement/SystemComponents.vue'),
            },
            {
              path: 'installation',
              name: 'system-installation',
              props: true,
              meta: {
                title: 'Winnow Hub - System Installation',
                requiredAbility: {
                  action: 'manage',
                  subject: 'systems-management',
                },
              },
              component: () => import('@/pages/SystemsManagement/SystemInstallation.vue'),
            },
            {
              path: 'settings',
              name: 'system-settings',
              props: true,
              meta: {
                title: 'Winnow Hub - System Settings',
                requiredAbility: {
                  action: 'manage',
                  subject: 'systems-management',
                },
              },
              component: () => import('@/pages/SystemsManagement/SystemSettings.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/installations-management',
      name: 'installations-management',
      component: () => import('@/pages/InstallationsManagement/InstallationsManagement.vue'),
      meta: {
        title: 'Winnow Hub - Installations',
        saveScroll: true,
        requiredAbility: {
          action: 'manage',
          subject: 'installations',
        },
      },
      redirect: {
        name: 'installations-management-list',
      },
      children: [
        {
          path: '',
          name: 'installations-management-list',
          component: () =>
            import('@/pages/InstallationsManagement/InstallationsManagementList.vue'),
          saveScroll: true,
          requiredAbility: {
            action: 'manage',
            subject: 'installations',
          },
        },
        {
          path: 'create',
          name: 'installations-management-create',
          props: true,
          component: () =>
            import('@/pages/InstallationsManagement/InstallationsManagementCreate.vue'),
          requiredAbility: {
            action: 'manage',
            subject: 'installations',
          },
        },
        {
          path: ':installationId',
          name: 'installations-management-edit',
          props: true,
          redirect: {
            name: 'installations-management-summary',
          },
          component: () =>
            import('@/pages/InstallationsManagement/InstallationsManagementEdit.vue'),
          children: [
            {
              path: 'summary',
              name: 'installations-management-summary',
              props: true,
              component: () =>
                import('@/pages/InstallationsManagement/InstallationsManagementSummary.vue'),
              requiredAbility: {
                action: 'manage',
                subject: 'installations',
              },
            },
            {
              path: 'settings',
              name: 'installations-management-settings',
              props: true,
              component: () =>
                import('@/pages/InstallationsManagement/InstallationsManagementSettings.vue'),
              requiredAbility: {
                action: 'manage',
                subject: 'installations',
              },
            },
            {
              path: 'systems',
              name: 'installations-management-systems',
              props: true,
              component: () =>
                import('@/pages/InstallationsManagement/InstallationsManagementSystems.vue'),
              requiredAbility: {
                action: 'manage',
                subject: 'installations',
              },
            },
          ],
        },
      ],
    },
    {
      path: '/training',
      name: 'training',
      component: () => import('@/pages/Training/Training.vue'),
      redirect: {
        name: 'training-chapter1',
      },
      children: [
        {
          path: 'chapter1',
          name: 'training-chapter1',
          component: () => import('@/pages/Training/TrainingChapter1.vue'),
        },
        {
          path: 'chapter2',
          name: 'training-chapter2',
          component: () => import('@/pages/Training/TrainingChapter2.vue'),
        },
        {
          path: 'chapter3',
          name: 'training-chapter3',
          component: () => import('@/pages/Training/TrainingChapter3.vue'),
        },
        {
          path: 'chapter4',
          name: 'training-chapter4',
          component: () => import('@/pages/Training/TrainingChapter4.vue'),
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('@/pages/NotFound/NotFound.vue'),
    },
    {
      path: '/ui',
      name: 'ui',
      component: () => import('@/pages/UI/UI.vue'),
      meta: {
        title: 'Winnow Hub - UI Kit',
        requiredAbility: {
          action: 'read',
          subject: 'generic',
        },
      },
      redirect: '/ui/elements',
      children: [
        {
          path: 'elements',
          component: () => import('@/pages/UI/Elements.vue'),
          meta: {
            requiredAbility: {
              action: 'read',
              subject: 'generic',
            },
          },
        },
        {
          path: 'forms',
          component: () => import('@/pages/UI/Forms.vue'),
          meta: {
            requiredAbility: {
              action: 'read',
              subject: 'generic',
            },
          },
        },
        {
          path: 'icons',
          component: () => import('@/pages/UI/Icons.vue'),
          meta: {
            requiredAbility: {
              action: 'read',
              subject: 'generic',
            },
          },
        },
        {
          path: 'typography',
          component: () => import('@/pages/UI/Typography.vue'),
          meta: {
            requiredAbility: {
              action: 'read',
              subject: 'generic',
            },
          },
        },
        {
          path: 'tables',
          component: () => import('@/pages/UI/Tables.vue'),
          meta: {
            requiredAbility: {
              action: 'read',
              subject: 'generic',
            },
          },
        },
        {
          path: 'collapsible-areas',
          component: () => import('@/pages/UI/CollapsibleAreas.vue'),
          meta: {
            requiredAbility: {
              action: 'read',
              subject: 'generic',
            },
          },
        },
        {
          path: 'page-elements',
          component: () => import('@/pages/UI/PageElements.vue'),
          meta: {
            requiredAbility: {
              action: 'read',
              subject: 'generic',
            },
          },
        },
      ],
    },
    {
      path: '/access-denied',
      name: 'access-denied',
      component: () => import('@/pages/AccessDenied/AccessDenied.vue'),
      meta: {
        title: 'Winnow Hub - Access Denied',
      },
    },
  ],
})

/**
 * Check if user has permissions to access a certain route
 *
 * If the `route.meta` has a `requiredAbility` prop with a `subject` and an `action`,
 * the user needs to have those permissions in order to access the route
 *
 * If no permissions are required, anyone can access those routes.
 * For now, those universally accessible routes are the error pages.
 *
 * There are a few `generic` pages that almost everybody can access, the exception here being
 * `Tracker Licenses` who are nost supposed to have permissions to enter Hub
 *
 * @param {Object} route
 * @returns {Boolean} wether the user can access a route or not
 */
const checkRouteAccess = (route) => {
  if (route.meta && route.meta.requiredAbility) {
    const { action, subject } = route.meta.requiredAbility
    return ability.can(action, subject)
  }
  return true
}

router.beforeEach((to, from, next) => {
  const routeWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)
  const user = store.getters['auth/user']
  const userFullName = store.getters['auth/userFullName']
  const { selectedSite } = useSelectedSite({ userId: user.id })
  const selectedSiteQualifiedName = selectedSite ? selectedSite.qualifiedName : null
  const orgFullName = user.subjectOrganisationFullName
  /**
   * Check each matched route for permissions and decide if user has access
   * This is based on the requiredAbility inside of each meta attribute on route configurations
   */
  const cannotAccessRoute = !to.matched.every(checkRouteAccess)
  /**
   * A `generic` permission is injected (manually, not coming from the BE)
   * for almost everybody in order to access pages like:
   * home, setup, job title configuration
   *
   * If access to generic pages is prohibited, it means the user can't view any pages so they should be redirected to `access-denied`
   */
  const cannotAccessAnyRoute = ability.cannot('read', 'generic')
  const { analytics } = useAnalytics()
  analytics.identify(user.id, {
    username: user.email,
    organization: user.subjectOrganization,
    selectedSite: selectedSiteQualifiedName,
    jobTitle: user.jobTitle,
  })

  if (import.meta.env.VUE_APP_INTERCOM_INTEGRATION) {
    window.Intercom('boot', {
      app_id: import.meta.env.VUE_APP_INTERCOM_INTEGRATION,
      name: userFullName,
      email: user.email,
      user_id: user.id,
      language_override: store.getters['auth/userSettings'].locale.code,
      parent_organisation: pickLevelOrgName({ orgFullName, level: 1 }),
      subsidiary: pickLevelOrgName({ orgFullName, level: 2 }),
      region: pickLevelOrgName({ orgFullName, level: 3 }),
      country: pickLevelOrgName({ orgFullName, level: 4 }),
      site_name: selectedSiteQualifiedName,
      user_role: user.groups.join(', '),
      job_title: user.jobTitle,
    })
  }

  /**
   * Unnecessarily complex logic coming up
   * @TODO: needs to be refactored sometime
   *
   * First of all, error pages need to be fulfilled and go through. Nothing should stop these redirects.
   * This first rule should also prevent an infinite loop from taking users from a 404 or access-denied page
   * to the user's route and back to error pages depending on route guard logic.
   * If we're not dealing with an error, we need to check if the user was set up (language and job title chosen), otherwise redirect them to the setup page
   * Then we have to check if existing users have a job title added to their profile. This is a required step for all non admins
   * After that, is time to check for actual permissions and direct the user to the homepage or their route of choice.
   *
   */
  if (['404', 'access-denied'].includes(to.name)) {
    next()
    stop
  } else if (!store.getters['auth/isSetupDone'] && to.name !== 'setup' && !cannotAccessAnyRoute) {
    next('/setup')
  } else if (
    !user.jobTitle &&
    !store.getters['auth/isWinnowUser'] &&
    !['job-title', 'setup'].includes(to.name)
  ) {
    next('/job-title')
  } else if (
    store.getters['auth/isSSOUser'] &&
    !store.getters['auth/rolesString'] &&
    !['sso-welcome', 'job-title', 'setup'].includes(to.name)
  ) {
    next('/sso-welcome')
  } else if (cannotAccessRoute) {
    if (cannotAccessAnyRoute) {
      next('/access-denied')
    } else {
      next('/')
    }
  } else {
    next()
  }

  if (routeWithTitle) {
    document.title = routeWithTitle.meta.title

    /*
     * Hacky way of tracking the page change in segment AFTER all other
     * click event handlers have been tracked on previous route.
     * When using <router-link>, all the route changing logic and hooks
     * are executed before click event handlers are fired.
     * Basically, keep this async
     */
    setTimeout(() => {
      analytics.page(routeWithTitle.meta.title, {
        package: selectedSite ? selectedSite.packageName : null,
        fullPath: to.fullPath,
        path: to.path,
        params: to.params,
        query: to.query,
      })
    })
  }
})

export default router
