import { keyBy, mapValues } from 'lodash-es'
import axios, { spitURL } from '../api-axios'
import encode from '@/utils/strict-uri-encode'
import mapOrganisation from './mappings/get-organisation'
import mapSite from './mappings/get-site'
import mapWasteTarget from './mappings/get-waste-target'
import mapSectorProperties from './mappings/get-sector-properties'
import mapOrgLocalisation from './mappings/get-org-localisation'
import sendOrganisation from './mappings/send-organisation'
import mapOrgPackages from './mappings/get-org-packages'
import mapSendingControls from './mappings/get-sending-controls'
import sendWasteTarget from './mappings/send-waste-target'
import mapUser from './mappings/get-user'
import sendUser from './mappings/send-user'
import mapDailyReportsConfig from './mappings/get-daily-reports-config'
import mapSummaryReportsConfig from './mappings/get-summary-reports-config'
import mapTransactionsExtractConfig from './mappings/get-transactions-extract-config'
import mapReportingParams from './mappings/get-reporting-params'
import mapSitesCollection from './mappings/get-sites-collection'

export default {
  fetchChildren(id, query = {}) {
    return axios.get(spitURL(`organisation/${encode(id)}/children`, query))
  },
  fetchTree(id, query = {}) {
    return axios
      .get(spitURL(`organisation/${encode(id)}/tree`, query))
      .then((response) => response.data)
      .then(({ data, metadata }) => {
        metadata.levels[0] = 'Root'
        return {
          records: keyBy(data.map(mapOrganisation), 'id'),
          metadata: metadata,
        }
      })
  },
  fetchSites(query = {}) {
    return axios
      .get(spitURL(`organisation/v2/sites`, query))
      .then((response) => response.data)
      .then(({ data }) => {
        return {
          records: keyBy(data.map(mapSite), 'legacyId'),
        }
      })
  },
  postNode({ payload, parentId }) {
    let url = `organisation/${parentId}`
    let level = payload.level
    // if we get a 'SITE_SHORTCUT' level id than we hardcode the following payload:
    if (level === 'SITE_SHORTCUT') {
      payload.level = 14
      url = `organisation/${parentId}/site-shortcut`
    }
    return axios
      .post(url, sendOrganisation(payload))
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapOrganisation)
  },
  fetchPackages() {
    return axios
      .get('organisation/packages')
      .then((resp) => resp.data)
      .then((json) => json.data.map(mapOrgPackages))
  },
  fetchLocalisation() {
    return axios
      .get('/organisation/localisation')
      .then((resp) => resp.data)
      .then((json) => mapOrgLocalisation(json.data))
      .then((localisation) => {
        return {
          localeRecords: keyBy(localisation.locales, 'code'),
          currencyRecords: keyBy(localisation.currencies, 'code'),
          timezoneRecords: keyBy(localisation.timezones, 'code'),
          weightUnitRecords: keyBy(localisation.weightUnits, 'id'),
          temperatureRecords: keyBy(localisation.temperatureUnits, 'id'),
        }
      })
  },
  fetchNode(nodeId) {
    return axios
      .get(`organisation/${nodeId}`)
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapOrganisation)
  },
  moveNode({ oldModel, newModel }) {
    return axios
      .put('/organisation/move', {
        old: oldModel,
        new: newModel,
      })
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapOrganisation)
  },
  patchNode({ nodeId, data, query = {} }) {
    return axios
      .patch(spitURL(`/organisation/${nodeId}`, query), {
        old: sendOrganisation(data.old),
        new: sendOrganisation(data.new),
      })
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapOrganisation)
  },
  // Waste targets
  fetchWasteTargets(nodeId, query) {
    return axios
      .get(spitURL(`organisation/${encode(nodeId)}/waste-targets`, query))
      .then((response) => response.data)
      .then(({ data, metadata }) => {
        return {
          records: keyBy(data.map(mapWasteTarget), 'id'),
          targetsIds: data.map((target) => target.id),
          metadata,
        }
      })
  },
  postWasteTarget({ payload, nodeId }) {
    return axios.post(`organisation/${encode(nodeId)}/waste-targets`, sendWasteTarget(payload))
  },
  deleteWasteTarget({ nodeId, targetId }) {
    return axios.delete(`organisation/${encode(nodeId)}/waste-targets/${encode(targetId)}`)
  },
  patchWasteTarget({ nodeId, targetId, payload }) {
    return axios.patch(`organisation/${encode(nodeId)}/waste-targets/${encode(targetId)}`, {
      new: sendWasteTarget(payload.new),
      old: sendWasteTarget(payload.old),
    })
  },
  // Sector properties
  fetchNodeSectorProperties(nodeId) {
    return axios
      .get(`organisation/${encode(nodeId)}/sector-properties`)
      .then((response) => response.data)
      .then(({ data, metadata }) => {
        return {
          record: mapSectorProperties(data),
          metadata,
        }
      })
  },
  putNodeSectorProperties({ nodeId, payload }) {
    // Convert empty strings to null as the endpoint expects
    payload.old = mapValues(payload.old, (v) => (v === '' ? null : v))
    payload.new = mapValues(payload.new, (v) => (v === '' ? null : v))
    return axios
      .put(`organisation/${encode(nodeId)}/sector-properties`, payload)
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapSectorProperties)
  },

  // Sending Controls
  fetchNodeSendingControls(nodeId) {
    return axios
      .get(`organisation/${encode(nodeId)}/sending-controls`)
      .then((response) => response.data)
      .then(({ data, metadata }) => {
        return {
          record: mapSendingControls(data),
          metadata,
        }
      })
  },
  putNodeSendingControls({ nodeId, payload }) {
    return axios
      .put(`organisation/${encode(nodeId)}/sending-controls`, payload)
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapSendingControls)
  },

  // Daily Reports subscribers
  fetchNodeDailyReportsSubscribers(nodeId) {
    return axios
      .get(`organisation/${encode(nodeId)}/report-recipients/daily`)
      .then((response) => response.data)
      .then(({ data, metadata }) => {
        return {
          record: data.map(mapUser),
          metadata,
        }
      })
  },
  putNodeDailyReportsSubscribers({ nodeId, payload }) {
    return axios
      .put(`organisation/${encode(nodeId)}/report-recipients/daily`, {
        old: payload.old.map(sendUser),
        new: payload.new.map(sendUser),
      })
      .then((resp) => resp.data)
      .then(({ data }) => data.map(mapUser))
  },

  // Summary Reports subscribers
  fetchNodeSummaryReportsSubscribers(nodeId) {
    return axios
      .get(`organisation/${encode(nodeId)}/report-recipients/summary`)
      .then((response) => response.data)
      .then(({ data, metadata }) => {
        return {
          record: data.map(mapUser),
          metadata,
        }
      })
  },
  putNodeSummaryReportsSubscribers({ nodeId, payload }) {
    return axios
      .put(`organisation/${encode(nodeId)}/report-recipients/summary`, {
        old: payload.old.map(sendUser),
        new: payload.new.map(sendUser),
      })
      .then((resp) => resp.data)
      .then(({ data }) => data.map(mapUser))
  },

  // Cross Site subscribers
  fetchNodeCrossSiteReportsSubscribers(nodeId) {
    return axios
      .get(`organisation/${encode(nodeId)}/report-recipients/cross-site`)
      .then((response) => response.data)
      .then(({ data, metadata }) => {
        return {
          record: data.map(mapUser),
          metadata,
        }
      })
  },
  putNodeCrossSiteReportsSubscribers({ nodeId, payload }) {
    return axios
      .put(`organisation/${encode(nodeId)}/report-recipients/cross-site`, {
        old: payload.old.map(sendUser),
        new: payload.new.map(sendUser),
      })
      .then((resp) => resp.data)
      .then(({ data }) => data.map(mapUser))
  },

  // Transactions subscribers
  fetchNodeTransactionsExtractSubscribers(nodeId) {
    return axios
      .get(`organisation/${encode(nodeId)}/report-recipients/transaction-extract`)
      .then((response) => response.data)
      .then(({ data, metadata }) => {
        return {
          record: data.map(mapUser),
          metadata,
        }
      })
  },
  putNodeTransactionsExtractSubscribers({ nodeId, payload }) {
    return axios
      .put(`organisation/${encode(nodeId)}/report-recipients/transaction-extract`, {
        old: payload.old.map(sendUser),
        new: payload.new.map(sendUser),
      })
      .then((resp) => resp.data)
      .then(({ data }) => data.map(mapUser))
  },

  // Daily Reports configs
  fetchNodeDailyReportsConfigs(nodeId) {
    return axios
      .get(`organisation/${encode(nodeId)}/report-configs/daily`)
      .then((response) => response.data)
      .then(({ data }) => mapDailyReportsConfig(data))
  },

  patchNodeDailyReportsConfigs({ nodeId, payload }) {
    return axios
      .patch(`organisation/${encode(nodeId)}/report-configs/daily`, payload)
      .then((response) => response.data)
      .then(({ data }) => mapDailyReportsConfig(data))
  },

  // Summary Reports config
  fetchNodeSummaryReportsConfigs(nodeId) {
    return axios
      .get(`organisation/${encode(nodeId)}/report-configs/summary`)
      .then((response) => response.data)
      .then(({ data }) => mapSummaryReportsConfig(data))
  },

  patchNodeSummaryReportsConfigs({ nodeId, payload }) {
    return axios
      .patch(`organisation/${encode(nodeId)}/report-configs/summary`, payload)
      .then((response) => response.data)
      .then(({ data }) => mapSummaryReportsConfig(data))
  },

  // Transactions extract
  fetchNodeTransactionsExtractConfigs(nodeId) {
    return axios
      .get(`organisation/${encode(nodeId)}/report-configs/transaction-extract`)
      .then((response) => response.data)
      .then(({ data }) => mapTransactionsExtractConfig(data))
  },

  postNodeTransactionsExtractConfigs({ nodeId, payload }) {
    return axios
      .post(`organisation/${encode(nodeId)}/report-configs/transaction-extract`, payload)
      .then((response) => response.data)
      .then(({ data }) => mapTransactionsExtractConfig(data))
  },

  patchNodeTransactionsExtractConfigs({ nodeId, payload }) {
    return axios
      .patch(`organisation/${encode(nodeId)}/report-configs/transaction-extract`, payload)
      .then((response) => response.data)
      .then(({ data }) => mapTransactionsExtractConfig(data))
  },

  // Reporting Parameters
  fetchNodeReportingParameters(nodeId) {
    return axios
      .get(`organisation/${encode(nodeId)}/report-params`)
      .then((response) => response.data)
      .then(({ data }) => mapReportingParams(data))
  },
  patchNodeReportingParameters({ nodeId, payload }) {
    return axios
      .put(`organisation/${encode(nodeId)}/report-params`, payload)
      .then((response) => response.data)
      .then(({ data }) => mapReportingParams(data))
  },

  fetchNodeAuthorizedSubscribers(query = {}, nodeId, cancelToken = null) {
    const options = cancelToken ? { cancelToken } : {}
    return axios
      .get(spitURL(`organisation/${encode(nodeId)}/report-recipients`, query), options)
      .then((response) => response.data)
      .then(({ data }) => data.map(mapUser))
  },

  fetchWeatherLocations(query = {}, cancelToken = null) {
    const options = cancelToken ? { cancelToken } : {}
    return axios
      .get(spitURL('organisation/weather-location', query), options)
      .then((response) => response.data)
      .then(({ data }) => data)
  },

  fetchSitesCollections(query = {}) {
    return axios
      .get(spitURL(`organisation/collections`, query))
      .then((response) => response.data)
      .then(({ data, metadata }) => {
        return {
          records: keyBy(data.map(mapSitesCollection), 'id'),
          organisationRecords: metadata.organisationDetails,
          sortedIds: data.map(({ id }) => id),
          metadata,
        }
      })
  },
  fetchSitesCollection({ collectionId }) {
    return axios
      .get(`organisation/collections/${collectionId}`)
      .then((response) => response.data)
      .then((json) => json.data)
      .then((collection) => {
        return {
          record: mapSitesCollection(collection),
          organisationRecord: mapOrganisation(collection.organisation),
        }
      })
  },

  postSitesCollection({ organisationId, name }) {
    return axios
      .post(`organisation/collections`, { organisationId, name })
      .then((response) => response.data)
      .then((json) => json.data)
      .then((collection) => {
        return {
          record: mapSitesCollection(collection),
          organisationRecord: mapOrganisation(collection.organisation),
        }
      })
  },
  patchSitesCollection({ collectionId, data }) {
    return axios
      .patch(`organisation/collections/${collectionId}`, {
        old: data.old,
        new: data.new,
      })
      .then((response) => response.data)
      .then((json) => json.data)
      .then((collection) => {
        return {
          record: mapSitesCollection(collection),
          organisationRecord: mapOrganisation(collection.organisation),
        }
      })
  },
  fetchCollectionsOrganisations() {
    return axios
      .get('organisation/collections/organisations')
      .then((response) => response.data)
      .then((json) => json.data)
      .then((organisations) => ({ records: keyBy(organisations, 'id') }))
  },
}
