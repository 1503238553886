export default function (settings) {
  return settings.map((setting) => ({
    key: setting.key,
    desired: setting.desired && {
      value: setting.desired.value,
      createdDate: setting.desired.created,
      updatedDate: setting.desired.updated,
    },
    reported: setting.reported && {
      value: setting.reported.value,
      createdDate: setting.reported.created,
      updatedDate: setting.reported.updated,
    },
  }))
}
